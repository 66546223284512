import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { fetchUserList, userStatusActive, userStatusBlock } from "../Redux/UserSlice";
import { toast } from "react-toastify";
import AddPayoutModal from "./AddPayoutModal";
import DecreasePayoutModal from "./DecreasePayoutModal";
import TablePagination from "./Pagination";
import { fetchUserWallet } from "../Redux/UserWalletSlice";

const UserTable = ({item})=> {
    const admin = JSON.parse(localStorage.getItem('taskbuzzarAdmin'))
    const [wallet, setWallet] = useState(null)
    const [showDropdown, setShowDropdown] =  useState(false);
    const [isAddModal, setIsAddModal] =  useState(false)
    const [userId, setUserId] = useState(null)
    const {search} = useLocation()
    const params = new URLSearchParams(search);
    const getCurrentPage = params.get('page')
    const [isDecreaseModal, setIsDecreaseAddModal] =  useState(false)
    const dropdownRef = useRef(null);
    const dispatch = useDispatch()

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
      };

      const handleAddPayout = (item)=> {
        setIsAddModal(true)
        setUserId(item._id)
      }
      const handleDecreasePayout = (item)=> {
        setIsDecreaseAddModal(true)
        setUserId(item._id)
      }

      const handleStatus = async({status, userId})=> {
        setShowDropdown(false)
        if(status === 'ACTIVE'){
            const statusBlockRes = await dispatch(userStatusBlock({adminId: admin._id, userId}))
            const res = statusBlockRes.payload;
            if(res.responseCode === 200){
                toast.success(res.responseMessage);
                dispatch(fetchUserList({adminId: admin._id, currentPage:getCurrentPage}))
            }else{
                toast.error(res.responseMessage)
            }
        }else {
            const statusActiveRes = await dispatch(userStatusActive({adminId: admin._id, userId}))
            const res = statusActiveRes.payload;
            if(res.responseCode === 200){
                toast.success(res.responseMessage);
                dispatch(fetchUserList({adminId: admin._id, currentPage:getCurrentPage}))
            }else{
                toast.error(res.responseMessage)
            }
        }
        
      }
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    return (
        <>
        <tr className='text-left justify-between' key={item._id}>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item._id}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2"><Link to={`../userdetails/${item._id}`}>{item.name}</Link></td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.email}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.mobileNumber}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2 text-sm"><span className={item.status  === 'BLOCK' ? 'statusBlock' : 'statusActive'}>{item.status}</span></td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.totalPayout}
                                              </td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2"><div className="flex justify-start gap-4">
                                                    <button className="py-1 px-2 bg-gray-100 rounded" onClick={()=>handleAddPayout(item)}><i className="fa-solid fa-plus"></i></button>
                                                    <button className="py-1 px-2 bg-gray-100 rounded" onClick={()=>handleDecreasePayout(item)}><i className="fa-solid fa-minus"></i></button>
                                                </div>
                                            </td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2"><div className="relative" ref={dropdownRef}><i className="fa-solid fa-ellipsis-vertical bg-gray-100 py-2 px-2 rounded hover:cursor-pointer" onClick={()=> setShowDropdown(!showDropdown)}></i>
                                               {showDropdown && <div className="absolute py-2 px-2 border border-sky-200 bg-white shadow w-40 rounded action-dropdown">
                                                    <button type="button" className="hover:bg-gray-50 w-full rounded text-left py-1 px-1 text-base" onClick={()=> handleStatus({status: item.status, userId : item._id})}>{item.status  === 'BLOCK' ? 'Active' : 'Block'}</button>
                                                </div>}
                                            </div></td>
        </tr>
        <AddPayoutModal isAddModal={isAddModal} setIsAddModal={setIsAddModal} userId={userId}/>
        <DecreasePayoutModal isDecreaseModal={isDecreaseModal} setIsDecreaseAddModal={setIsDecreaseAddModal} userId={userId}/>
        </>
    )
}
export default UserTable;