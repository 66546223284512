import React, { useState } from "react";
import { toast } from "react-toastify";
import { addPayoutToAccount, decreasePayoutFromAccount } from "../Redux/UserWalletSlice";
import { useDispatch } from "react-redux";

const DecreasePayoutModal = ({isDecreaseModal, setIsDecreaseAddModal, userId})=> {
    const admin = JSON.parse(localStorage.getItem('taskbuzzarAdmin'))
    const [formData, setFormData] = useState({})
    const dispatch = useDispatch()

    const handleChange = (e)=> {
        setFormData({ ...formData, [e.target.name]: e.target.value, userId})
    }

    const handleSubmit = async(e)=> {
        e.preventDefault()
        const decreasePayoutRes = await dispatch(decreasePayoutFromAccount({adminId: admin._id, formData}))
        const res = decreasePayoutRes.payload;
        toast.success(res.responseMessage)
        setIsDecreaseAddModal(false)
    }
    return (
        <>
        {/* Main modal */}
{isDecreaseModal && <div class="postback-modal overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full flex">
    <div class="relative p-4 w-full max-w-2xl max-h-full">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Add Payout
                </h3>
                <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={()=> setIsDecreaseAddModal(false)}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div class="p-4 md:p-5 space-y-4 text-left flex justify-between items-center">
               <form onSubmit={handleSubmit}>
                    <div className="flex justify-between gap-4">
                        <input type="text" name='payout' placeholder="payout" className="border border-gray-200 rounded px-2 py-2 w-full md:w-3/4" onChange={handleChange}/>
                        <button className="py-2 px-2 bg-sky-700 text-white rounded" type="submit">Submit</button>
                    </div>
               </form>
            </div>
            <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button type="button" class="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={()=> setIsDecreaseAddModal(false)}>Close</button>
            </div>
        </div>
    </div>
</div>}
        </>
    )
}
export default DecreasePayoutModal