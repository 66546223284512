import React, { useEffect, useRef, useState } from "react";
import profileImg from '../../../assets/images/profile.png';
import { Link, useNavigate } from "react-router-dom";
import logo from '../../../assets/images/main-logo.png';
import PostbackModal from "../../PostbackModal";

const AdminNavbar = ()=> {
    const [isModal, setIsModal] =  useState(false)
    const [showDropdown, setShowDropdown] =  useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate()
    const handleLgout = ()=> {
        localStorage.removeItem('taskbuzzarAdmin')
        navigate('../login')
    }
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
      };
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    return (
        <>
        <div className="py-1 px-8 shadow admin-nav">
            <div className="flex justify-between items-center">
            <div className="logo w-1/2 md:w-1/4 flex justify-start">
                            <Link to='/'><img src={logo} alt="logoImg" className="w-1/5"/></Link>
                        </div>
            <div className="relative" ref={dropdownRef}>
                <img src={profileImg} alt='profileImg' className="w-10" onClick={()=> setShowDropdown(!showDropdown)}/>
               {showDropdown && <div className="border border-sky-200 py-2 px-2 rounded w-48 absolute right-0 text-left bg-white z-40">
                    <ul>
                        <li className="text-sky-700 pb-2 cursor-pointer" onClick={handleLgout}><i className="fa-solid fa-arrow-right-from-bracket"></i> Logout</li>
                        <li className="text-sky-700 pb-2 cursor-pointer"><i className="fa-solid fa-user"></i> Profile</li>
                        <li className="text-sky-700 pb-2 cursor-pointer"><button className="px-4" onClick={()=> setIsModal(true)}>Postback</button></li>
                    </ul>
                </div>}
            </div>
            
            </div>
        </div>
        <PostbackModal isModal={isModal} setIsModal={setIsModal}/>
        </>
    )
}

export default AdminNavbar;