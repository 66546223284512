import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// import { fetchUserList, userStatusActive, userStatusBlock } from "../Redux/UserSlice";
import { toast } from "react-toastify";
import { approveAmountByAdmin, fetchWithdrawRequest } from "../Redux/UserWalletSlice";

const WithdrawReqTable = ({item})=> {
    const admin = JSON.parse(localStorage.getItem('taskbuzzarAdmin'))
    const [showDropdown, setShowDropdown] =  useState(false);
    const dropdownRef = useRef(null);
    const dispatch = useDispatch()

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
      };
      const handleApprove = async(item)=> {
        const approveAmountRes = await dispatch(approveAmountByAdmin({adminId: admin._id, transactionId : item.transactionId}))
        const res = approveAmountRes.payload;
        if(res?.responseCode === 200){
          toast.success(res?.responseMessage)
          dispatch(fetchWithdrawRequest())
        }else{
          toast.error(res?.responseMessage)
        }
      }

    //   const handleStatus = async({status, userId})=> {
    //     if(status === 'ACTIVE'){
    //         const statusBlockRes = await dispatch(userStatusBlock({adminId: admin._id, userId}))
    //         const res = statusBlockRes.payload;
    //         if(res.responseCode === 200){
    //             toast.success(res.responseMessage);
    //             dispatch(fetchUserList(admin._id))
    //         }else{
    //             toast.error(res.responseMessage)
    //         }
    //     }else {
    //         const statusActiveRes = await dispatch(userStatusActive({adminId: admin._id, userId}))
    //         const res = statusActiveRes.payload;
    //         if(res.responseCode === 200){
    //             toast.success(res.responseMessage);
    //             dispatch(fetchUserList(admin._id))
    //         }else{
    //             toast.error(res.responseMessage)
    //         }
    //     }
        
    //   }
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    return (
        <>
        <tr className='text-left justify-between' key={item._id}>
                                            {/* <td className="border border rounded-tl border-gray-300 py-2 px-2">{item._id}</td> */}
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.userId}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.amount}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.transactionTime}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.transactionId}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.paytmNumber ? item.paytmNumber : item.upi}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2 text-sm"><span className={item.status  === 'APPROVED' ? 'statusActive' : 'statusBlock'}>{item.status}</span></td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2"><div className="relative" ref={dropdownRef}><i className="fa-solid fa-ellipsis-vertical bg-gray-100 py-2 px-2 rounded hover:cursor-pointer" onClick={()=> setShowDropdown(!showDropdown)}></i>
                                               {showDropdown && <div className="absolute py-2 px-2 border border-sky-200 bg-white shadow w-40 rounded action-dropdown">
                                                    <button type="button" className="hover:bg-gray-50 w-full rounded text-left py-1 px-1 text-base" onClick={()=>handleApprove(item)}>{item.status  === 'APPROVED' ? 'Inactive' : 'Active'}</button>
                                                </div>}
                                            </div></td>
                                        </tr>
        </>
    )
}
export default WithdrawReqTable;