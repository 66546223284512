import React, { useEffect } from "react";
import profile from '../assets/images/profile.png'
import { useDispatch, useSelector } from "react-redux";
import { fetchUserWallet } from "../Redux/UserWalletSlice";

const UserDetail = ()=> {
    const user = JSON.parse(localStorage.getItem('loggedInUser'))
    const wallet = useSelector((state)=> state.userWallet.wallet)
    const dispatch = useDispatch()

    useEffect(()=> {
        dispatch(fetchUserWallet(user._id))
    },[])
    return (
        <>
        <div className="shadow py-6 px-2 rounded-lg text-center bg-sky-900 min-h-full">
                        <div className="flex items-center justify-center">
                            <div className="profile-img mb-2 bg-sky-100">
                                <img src={profile} alt='profile Img' className="pb-2 w-20 text-center"/>
                            </div>
                        </div>
                        <h2 className="font-bold text-2xl text-white">Hello, {user.name}</h2>
                        <p className="text-sm text-gray-400">{user.email}</p>
                        <div className="flex justify-between items-center gap-4 pt-10 px-2">
                            <div className="border border-gray-500 px-1 py-1 rounded w-full">
                                <h2 className="font-bold text-3xl text-sky-100">₹ {wallet}</h2>
                                <p className="text-gray-300">Total Earning</p>
                            </div>
                            <div className="border border-gray-500 px-1 py-1 rounded w-full">
                                <h2 className="font-bold text-3xl text-sky-100">₹ {wallet}</h2>
                                <p className="text-gray-300">Today Earning</p>
                            </div>
                        </div>
                    </div>
        </>
    )
}

export default UserDetail;