import React from "react";
import { Link } from "react-router-dom";

const Footer = ()=> {
    return (
        <>
         {/* <div className="px-10 bg-gray-50">
            <div className="footer-sec py-10 text-left">
                <div className="flex justify-between gap-10">
                    <div className="w-1/4">                                         
                        <h4 className="text-sky-800">Get in touch with us</h4>
                        <div className="col-md-3">
                            <h2 className="font-bold text-2xl text-neutral-600">Taskbuzzar</h2>
                        </div>
                        <div className="footer-contact pt-2">
                            <p className="text-neutral-500 flex gap-2 items-center justify-start"><i className="fa-solid fa-phone text-neutral-500 text-sm"></i> <span>9876-543210</span></p>
                            <p className="text-neutral-500 flex gap-2 items-center justify-start"><i className="fa-solid fa-envelope text-neutral-500 text-sm"></i> <span>support@taskbuzzar.com</span></p>
                        </div>
                    </div>
                    <div className="w-1/2">
                        <h4 className="text-sky-800">About</h4>
                        <p className="text-neutral-500 text-base">
                        Now a days mobile phones has become a part of everyone's lifestyle and people tend to spend much time on phone in their daily life. But without doing anything on phone that add benefits to your life is just waste of time. 
                        </p>
                    </div>
                    <div className="w-1/4">
                        <h4 className="text-sky-800">Useful Links</h4>
                        <ul className="footer-links">
                            <li><Link to='/' className="text-neutral-500 text-sm">Term and conditions</Link></li>
                            <li><Link to='/' className="text-neutral-500 text-sm">Privacy policy</Link></li>
                            <li><Link to='/' className="text-neutral-500 text-sm">Contact us</Link></li>
                            <li><Link to='/' className="text-neutral-500 text-sm">Important Websites</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            </div> */}
            <div className="px-2 md:px-16 py-5 flex justify-between bg-gray-50 items-center flex-col md:flex-row gap-4">
                <div className="flex flex-col md:flex-row">
                    <p className="text-neutral-600 font-light text-sm md:text-sm border-0 md:border md:border-r-2 border-gray-300 md:border-y-0 md:border-l-0 md:pr-2 pb-2 md:pb-0">Copyright © <Link to='/'><b className="text-gray-700 font-bold">taskbazzar.com</b></Link></p>
                    <p className="text-neutral-600 font-light text-sm md:text-sm md:pl-2">Development By <Link to='https://www.adomobi.com/' target="_blank"><b className="text-gray-700 font-bold">AdoMobi</b></Link></p>
                </div>
                <div className="">
                    <ul className="social-network flex gap-4">  
                        <li className="w-10 h-10 bg-gray-800 rounded-full flex justify-center items-center"><Link to='/'><i className="fa-brands fa-facebook-f text-white"></i></Link></li>
                        <li className="w-10 h-10 bg-gray-800 rounded-full flex justify-center items-center"><Link to='/'><i className="fa-brands fa-x-twitter text-white"></i></Link></li>
                        <li className="w-10 h-10 bg-gray-800 rounded-full flex justify-center items-center"><Link to='/'><i className="fa-brands fa-linkedin-in text-white"></i></Link></li>
                        {/* <li className="w-10 h-10 bg-gray-800 rounded-full flex justify-center items-center"><Link to='/'><i className="fa-brands fa-pinterest-p text-white"></i></Link></li> */}
                    </ul>
                </div>
                <div className="">
                        <ul className="footer-links flex gap-4">
                            <li><Link to='/termsconditions' className="text-neutral-700 text-sm">Terms of Use</Link></li>
                            <li><Link to='/privacypolicy' className="text-neutral-700 text-sm">Privacy policy</Link></li>
                        </ul>
                    </div>
            </div>
        </>
    )
}
export default Footer