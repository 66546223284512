import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';

const AboutSec = ()=> {
    return (
        <>
        <div className="about-sec py-10 md:py-16">
            <div className="flex flex-col md:flex-row justify-between px-4 md:px-10 text-left gap-8">
                <div className="w-full md:w-1/2">
                    <h4 className="text-2xl mb-2">About</h4>
                    <p className="text-neutral-400 text-sm md:text-base">
					Now a days mobile phones has become a part of everyone's lifestyle and people tend to spend much time on phone in their daily life. But without doing anything on phone that add benefits to your life is just waste of time. Some of people used to earn money online by using thier mobile. You can also earn earn money instantly by checking and replying few mails that you will get everyday on taskbazzar.com. Very soon will be adding more features for online income.
					</p>
                </div>
                <div className="w-full md:w-1/2">
                <h4 className="text-2xl mb-2">What Our Members Are Saying</h4>
                <Swiper navigation={false} pagination={{ clickable: true }} modules={[Navigation, Pagination]} className="mySwiper">
                    <SwiperSlide>
                        <blockquote className="text-neutral-400 mb-4 italic text-sm md:text-base">Hello Friends, I'm doing BA. This online earning website is very useful. I have earned 495 in just 10 days. <br /> I use this website for 30 minutes daily. 
                        </blockquote>
                        <h4>Ankit Sain <span>-Student</span></h4></SwiperSlide>
                    <SwiperSlide>
                    <blockquote className="text-neutral-400 mb-4 italic text-sm md:text-base">
                            I'm house wife. I use to work on this website wherever in get time in between my daily routine. This is an another source for me to earn money online.
                        </blockquote>
                        <h4>Shreya Pathak <span>― House Wife </span></h4></SwiperSlide>
                    <SwiperSlide>
                        <blockquote className="text-neutral-400 mb-4 italic text-base">
                            Best money making website friends. Earned <i className="fa fa-inr"></i>250 So far and from them 50 has been credited to my paytm wallet.
                        </blockquote>
                        <h4>Aaditi Sharma<span>― Student </span></h4>
                        </SwiperSlide>
                        <SwiperSlide>
                        <blockquote className="text-neutral-500 mb-4 italic text-sm md:text-base">
                            Hi, I have placed a withdrawal request of 100.  Its so easy and quick to make money online. I'm earning by referring my other friends too. Thank You
                        </blockquote>
                        <h4>Rohit Khanna<span>― Student </span></h4>
                        </SwiperSlide>
                </Swiper>
                </div>
            </div>
        </div>
        </>
    )
}

export default AboutSec;