import React from "react";

const Contact = ()=> {
    return (
        <>
        <div className="header-subBanner">
            <div className="container px-4 md:px-18 h-full">
            <div className="">
                <h2 className="font-extrabold text-4xl text-sky-100 text-center">Contact us</h2>
                </div>
            </div>
        </div>
         <div className="py-10  px-4 md:px-10 container mx-auto">
        <div className="sec-heading pb-4">
            {/* <h2 className="font-bold text-3xl md:text-4xl text-neutral-700 pb-2">Contact us</h2> */}
            <small className="text-base md:text-lg text-neutral-600">Get in touch with us by filling form belowe</small>
        </div>
        <div className="registration-form py-4 md:py-8 px-4 w-full md:w-1/2 mx-auto bg-white rounded shadow text-left">
            <form>
            <div class="mb-4">
                        <label class="block text-grey-darker text-sm font-bold mb-2" for="name">Name</label>
                        <input class="appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="name" type="text" placeholder="Your name" />
                    </div>
                <div class="mb-4">
                    <label class="block text-grey-darker text-sm font-bold mb-2" for="email">Email Address</label>
                    <input class="appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="email" type="email" placeholder="Your email address" />
                </div>
                <div class="mb-4">
                    <label class="block text-grey-darker text-sm font-bold mb-2" for="subject">Subject</label>
                    <input class="appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="subject" type="text" placeholder="Subject" />
                </div>
                <div class="mb-4">
                    <label class="block text-grey-darker text-sm font-bold mb-2" for="message">Message</label>
                    <textarea class="appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="message" type="text" placeholder="Message" />
                </div>
                <div className="flex items-center gap-4">
                    <div className="w-1/2">
                        <button type="submit" className="bg-red-600 text-white w-full py-3">Submit</button>
                    </div>
                </div>
            </form>
        </div>
        </div>
        </>
    )
}
export default Contact;