import React, { useState } from "react";
import { toast } from "react-toastify";
import { withdrawAmountReq } from "../Redux/UserWalletSlice";
import { useDispatch } from "react-redux";

const WithdrawRequestModal = ({isModal, setIsModal})=> {
    const [formData, setFormData] = useState({})
    const [transectionMethod, setTransectionMethod] = useState()
    const user = JSON.parse(localStorage.getItem('loggedInUser'))
    const dispatch = useDispatch()

    const handleChange = (e)=> {
        setFormData({ ...formData, [e.target.name]: e.target.value, userId: user._id})
    }

    const handleTransectionMethod = (e)=> {
        setTransectionMethod(e.target.value)
        console.log('transection method',e.target.value)
    }
    const handleSubmit = async(e)=> {
        e.preventDefault()
        const withdrawReqRes = await dispatch(withdrawAmountReq(formData))
        const res = withdrawReqRes.payload;
        toast.success(res.message)
        setIsModal(false)
    }
    return (
        <>
        
        {/* Main modal */}
{isModal && <div class="postback-modal overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full flex">
    <div class="relative p-4 w-full max-w-2xl max-h-full">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Withdraw Amount Request
                </h3>
                <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={()=> setIsModal(false)}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
                
            </div>
            <div class="p-4 md:p-5 space-y-4 text-left flex justify-between items-center">
               <form onSubmit={handleSubmit}>
                    <div className="flex justify-between gap-4">
                        <input type="text" name='amount' placeholder="Amount" className="border border-gray-200 rounded px-2 py-2 w-full md:w-3/4" onChange={handleChange}/>
                        <select className="border border-gray-200 rounded px-2 py-2 w-full md:w-3/4" onChange={handleTransectionMethod} required>
                            <option value='' hidden>Select transection method</option>
                            <option value='paytm'>Paytm</option>
                            <option value='upi'>UPI</option>
                        </select>
                    </div>
                    {transectionMethod && <div className="flex justify-between gap-4 pt-3">
                        {transectionMethod === 'paytm' && <input type="text" name='paytmNumber' placeholder="paytmNumber" className="border border-gray-200 rounded px-2 py-2 w-full md:w-3/4" onChange={handleChange}/>}
                        {transectionMethod === 'upi' && <input type="text" name='upi' placeholder="upi" className="border border-gray-200 rounded px-2 py-2 w-full md:w-3/4" onChange={handleChange}/>}
                    </div>}
                    <div className="pt-3">
                        <button className="py-2 px-2 bg-sky-700 text-white rounded" type="submit">Submit</button>
                    </div>
               </form>
            </div>
            <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button type="button" class="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={()=> setIsModal(false)}>Close</button>
            </div>
        </div>
    </div>
</div>}
        </>
    )
}
export default WithdrawRequestModal