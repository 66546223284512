import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BaseUrl from "../Api/BaseUrl";

const CreateAccount = ()=> {
    const [formData, setFormData] = useState({})
    const navigate = useNavigate()
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let valid = true;
        const newErrors = {};
    
        // Check username
        if (!formData.name.trim()) {
          newErrors.name = 'Username is required';
          valid = false;
        }
    
        // Check email
        if (!formData.email.trim()) {
          newErrors.email = 'Email is required';
          valid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          newErrors.email = 'Invalid email address';
          valid = false;
        }
    
        // Check phone numbar
        if (!formData.mobileNumber.trim()) {
            newErrors.mobileNumber = 'Phone number is required';
            valid = false;
          } else {
            // Check if the phone number follows a specific pattern (e.g., xxx-xxx-xxxx)
            const phoneRegex = /^\d{10}$/;
            if (!phoneRegex.test(formData.mobileNumber)) {
              newErrors.mobileNumber = 'Invalid phone number (exactly 10 digits)';
              valid = false;
            }
        }
    
        setErrors(newErrors);
        return valid;
      };

    const handleChange = (e)=> {
        setFormData({...formData, [e.target.name]: e.target.value})
    }
    const handleSignup = async(e)=>{
        e.preventDefault()
        if (validateForm()) {
        const response = await fetch(`${BaseUrl}user/signup`, {
            method: 'POST',
            headers: {
                'accept': 'application/json'
            },
            body: new URLSearchParams(formData)
        })
        const resData = await response.json()
        if(resData.responseCode === 200){
            toast.success(resData.responseMessage)
            navigate('/dashboard')
            localStorage.setItem('loggedInUser',JSON.stringify(resData.responsResult) )
        }else{
            toast.error(resData.responseMessage)
        }
    }else{
        toast.error('Fill correct data')
    }
    }

    return (
        <>
        <div className="header-subBanner">
            <div className="container px-4 md:px-18 h-full">
                <div className="flex justify-end items-center flex-col md:flex-row h-full">
                   
                </div>
            </div>
        </div>
        <div className="py-10 px-4 md:px-10 container mx-auto">
        <div className="sec-heading pb-4">
            {/* <h2 className="font-bold text-4xl text-neutral-700 pb-2">Register to Get Upto ₹99</h2> */}
            <h2 className="font-bold text-3xl text-neutral-700 pb-2">Registration is free and always rewarding.</h2>
        </div>
        <div className="registration-form py-8 px-4 w-full md:w-1/2 mx-auto bg-white rounded shadow text-left">
            <form onSubmit={handleSignup}>
            <div class="flex flex-col md:flex-row mb-4">
                    <div class="w-full md:w-1/2 mr-1">
                        <label class="block text-grey-darker text-sm font-bold mb-2" for="username">Name</label>
                        <input class="appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="username" type="text" name='name' value={formData?.name} onChange={handleChange} placeholder="Your name" />
                        {errors.name && <p className="error text-red-600 text-sm pt-1">*{errors.name}</p>}
                    </div>
                    <div class="w-full md:w-1/2 mr-1">
                        <label class="block text-grey-darker text-sm font-bold mb-2" for="email">Email</label>
                        <input class="appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="email" type="email" name='email' value={formData?.email} onChange={handleChange} placeholder="Your email address" />
                        {errors.email && <p className="error text-red-600 text-sm pt-1">*{errors.email}</p>}
                    </div>
                </div>
                <div class="mb-4">
                    <label class="block text-grey-darker text-sm font-bold mb-2" for="mobile">Phone Number</label>
                    <input class="appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="mobile" type="tel" name='mobileNumber' value={formData?.mobileNumber} onChange={handleChange} placeholder="Your mobile number" />
                    {errors.mobileNumber && <p className="error text-red-600 text-sm pt-1">*{errors.mobileNumber}</p>}
                </div>
                <div class="mb-4">
                    <label class="block text-grey-darker text-sm font-bold mb-2" for="address">Address</label>
                    <input class="appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="address" type="text" name='address' value={formData?.address} onChange={handleChange} placeholder="Your address" />
                </div>
                <div class="mb-4">
                    <label class="block text-grey-darker text-sm font-bold mb-2" for="password">Password</label>
                    <input class="appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="password" type="password" name='password' value={formData?.password} onChange={handleChange} placeholder="Your secure password" />
                </div>
                <hr />
                <div className="mb-4 pt-2">
                    <p className="text-sm">By clicking <span className="bg-cyan-300 px-1">Register</span>, you agree to the <Link to='/'>Terms and Conditions</Link> set out by this site, including our Privacy policy.</p>
                </div>
                <div className="flex flex-col md:flex-row items-center gap-4">
                    <div className="w-full md:w-1/2">
                        <button type="submit" className="bg-red-600 text-white w-full py-3">Register</button>
                    </div>
                    <div className="w-full md:w-1/2">
                        <p>Already have an account?<Link to='/login'>Sign In</Link></p>
                    </div>
                </div>
            </form>
        </div>
        </div>
        </>
    )
}
export default CreateAccount