import React from "react";
import { Link } from "react-router-dom";

const Sidebar = ()=> {
    return (
        <>
        <div className="h-full overflow-auto bg-sky-700 text-left py-2 px-2">
            <h4 className="font-bold text-lg text-sky-100">Menu</h4>
            <div className="menus">
                <ul>
                    <li><Link to='../dashboard' className="w-full py-2 px-2 mb-3 text-sky-100 flex justify-start items-center gap-4 hover:text-sky-700 hover:bg-sky-100 rounded transition-all"><i className="fa-brands fa-windows"></i> <span>Dashboard</span></Link></li>
                    <li><Link to='../offer' className="w-full py-2 px-2 mb-3 text-sky-100 flex justify-start items-center gap-4 hover:text-sky-700 hover:bg-sky-100 rounded transition-all"><i className="fa-solid fa-bullhorn"></i> <span>Offer</span></Link></li>
                    <li><Link to='../advertiser' className="w-full py-2 px-2 mb-3 text-sky-100 flex justify-start items-center gap-4 hover:text-sky-700 hover:bg-sky-100 rounded transition-all"><i className="fa-solid fa-handshake"></i> <span>Advertiser</span></Link></li>
                    <li><Link to='../conversion' className="w-full py-2 px-2 mb-3 text-sky-100 flex justify-start items-center gap-4 hover:text-sky-700 hover:bg-sky-100 rounded transition-all"><i className="fa-solid fa-chart-line"></i> <span>Conversion</span></Link></li>
                    <li><Link to='../user' className="w-full py-2 px-2 mb-3 text-sky-100 flex justify-start items-center gap-4 hover:text-sky-700 hover:bg-sky-100 rounded transition-all"><i className="fa-solid fa-user"></i> <span>User</span></Link></li>
                    <li><Link to='../withdrawrequest' className="w-full py-2 px-2 mb-3 text-sky-100 flex justify-start items-center gap-4 hover:text-sky-700 hover:bg-sky-100 rounded transition-all"><i className="fa-solid fa-money-bill-transfer"></i> <span>Withdraw Request</span></Link></li>
                </ul>
            </div>  
        </div>
        </>
    )
}

export default Sidebar;