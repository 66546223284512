import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import BaseUrl from '../Api/BaseUrl';

export const fetchPostbackUrl = createAsyncThunk(
    'user/viewWallet',
    async ({userId, tid}) => {
        const response = await fetch(`${BaseUrl}s2s/postback?userId=${userId}&tid=${tid}`);
        const responseData = await response.json();
        return responseData
      }
)
export const fetchTrackingUrl = createAsyncThunk(
    'user/clickData',
    async ({userId, offerId}) => {
        const response = await fetch(`${BaseUrl}clickData/click?u=${userId}&o=${offerId}`);
      }
)

export const PostbackSlice = createSlice({
    name: 'postback',
    initialState: {
        wallet: null,
        trackingtUrl: '',
        message: null,
        loading : false,
        error : null
      },

      reducers: {
    
      },

      extraReducers: (builder) => {
        // fetch builder
       builder.addCase(fetchPostbackUrl.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(fetchPostbackUrl.fulfilled, (state, action) => {
         state.message =  action.payload.responseMessage;
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(fetchPostbackUrl.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
        // fetch builder
       builder.addCase(fetchTrackingUrl.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(fetchTrackingUrl.fulfilled, (state, action) => {
         state.message =  action.payload;
         console.log('trackingRes', action.payload)
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(fetchTrackingUrl.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });

    }
})

export default PostbackSlice.reducer