import React, { useEffect } from "react";
import money from '../../assets/images/online-earning.png'
import { Link } from "react-router-dom";
import UserDetail from "../../components/UserDetail";
import { useDispatch, useSelector } from "react-redux";
import { fetchOfferList } from "../../Redux/OfferSlice";
import { fetchTrackingUrl } from "../../Redux/PostbackSlice";
import BaseUrl from "../../Api/BaseUrl";

const Inbox = ()=>{
    const user = JSON.parse(localStorage.getItem('loggedInUser'))
    const trackingUrll = useSelector((state)=> state.postback.trackingtUrl) 
    const offerList = useSelector((state)=> state.offer.list)
    const dispatch = useDispatch()

    const handleTrackingUrl = async(offer)=>{
        dispatch(fetchTrackingUrl({userId: user._id, offerId: offer._id}))
    }

    useEffect(()=> {
        dispatch(fetchOfferList())
    },[])
    return (
        <>
        <div className="header-dashboardBanner">
            <div className="container px-4 md:px-18 h-full">
                <div className="flex justify-end items-center flex-col md:flex-row h-full">
                   
                </div>
            </div>
        </div>
        <div className="py-4 px-2 md:px-6 container user-dashboard">
            <p className="text-left pb-2 text-lg text-slate-500">Welcome to dashboard!</p>
            <div className="flex flex-col md:flex-row justify-between gap-4">
                <div className="w-full md:w-1/4">
                    <UserDetail />
                </div>
                <div className="w-full md:w-3/4">
                    <div className="shadow py-4 px-2 md:px-4 pb-4 md:pb-10 rounded-2xl text-center bg-gray-100">
                        <div className="py-4">
                            <div className="bg-white py-2 px-0 md:px-2 rounded text-left">
                                <div className="px-2 pt-2 md:pt-10 overflow-x-auto">
                                <p className="text-xl md:text-2xl text-gray-700">Participate in the offers below to earn money</p>
                                <div className="table w-full pt-4 overflow-hidden">
                                    <table class='shadow rounded w-full'>
                                    <thead class='sticky top-0'>
                                        <tr class='text-left'>
                                            {/* <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Id</th> */}
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Offer Icon</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Offer Name</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Category</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Cashback</th>
                                            {/* <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Revenue</th> */}
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Action</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Submit your task</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {offerList.map((item)=> <tr class='text-left'>
                                            {/* <td className="border border rounded-tl border-gray-300 py-2 px-2">{item._id}</td> */}
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2"><img src={item.OfferIcon} alt='offer image' className="w-10"/></td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2 underline hover:text-sky-600"><Link to={`../offerdetails/${item._id}`}>{item.OfferName}</Link></td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.category}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">₹ {item.payout}</td>
                                            {/* <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.revenue}</td> */}
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2 underline cursor-pointer"><Link to={`${BaseUrl}clickData/click?u=${user._id}&o=${item._id}`} target="_blank" className="text-nowrap">{item.buttonName}</Link></td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2"><span className="bg-green-600 py-2 px-3 rounded-full text-white text-sm hover:bg-green-700"><Link to='https://docs.google.com/forms/d/e/1FAIpQLScFL9ouWUIrwbZNariIUy37s4MR9Elw1InnRQfYBwfN4Ra0nw/viewform' target="_blank" className="text-nowrap">Submit Task</Link></span></td>
                                        </tr>)}
                                    </tbody>
                                    </table>
                                </div>
                                </div>
                                <div className="flex justify-between">
                                    <div className="w-0 md:w-2/3"></div>
                                <img src={money} alt='moneyImg' className="w-full md:w-1/3"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Inbox;