import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../../components/global/Admin/Sidebar";
import AdminNavbar from "../../../components/global/Admin/Navbar";
import { Link, useLocation } from "react-router-dom";
import BaseUrl from "../../../Api/BaseUrl";
import UserTable from "../../../components/UserTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchWithdrawRequest } from "../../../Redux/UserWalletSlice";
import WithdrawReqTable from "../../../components/WithdrawReqTable";
import TablePagination from "../../../components/Pagination";

const WithdrawRequest = ()=> {
    const [user, setUser] = useState([])
    const [totalPage, setTotalPage] = useState()
    const {search} = useLocation()
    const params = new URLSearchParams(search);
    const getCurrentPage = params.get('page')
    const admin = JSON.parse(localStorage.getItem('taskbuzzarAdmin'))
    const withdrawReqList = useSelector((state)=> state.userWallet.withdrawRequest)
    const dispatch = useDispatch()
    const fetchApiList = async()=> {
     const withdrawRequestRes = await dispatch(fetchWithdrawRequest({adminId: admin._id, currentPage: getCurrentPage}))
     const res = withdrawRequestRes.payload;
     if(res.responseCode  === 200){
        setTotalPage(res.totalPages)
     }
    }
    useEffect(()=> {
        fetchApiList()
    },[])
    return (
        <>
        <div className="container flex flex-col h-screen">
            <AdminNavbar />
            <div className="main flex justify-between grow">
                <div className="w-1/5">
                    <Sidebar />
                </div>
                <div className="w-4/5 py-4 px-4 text-left">
                    {/* <h2 className="font-bold text-2xl text-gray-800 pb-3">Offer</h2> */}
                    <div className="rounded bg-gray-100 px-4 py-4">
                        <div className="flex justify-between items-center">
                            <input type="text" placeholder="search" className="py-2 px-2 rounded" />
                            {/* <button className="py-2 px-4 bg-sky-700 text-sky-100 hover:text-white rounded"> <i className="fa-solid fa-plus"></i> Create offer</button> */}
                        </div>
                        <div className="w-full bg-white py-2 px-2 rounded flex justify-between text-left gap-4 mt-4">
                        <div className="table w-full pt-4">
                                    <table class='shadow rounded w-full'>
                                    <thead class='sticky top-0'>
                                        <tr class='text-left justify-between'>
                                            {/* <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Id</th> */}
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">User Id</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Amount</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Transection time</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Transection Id</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Payment method</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Status</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {withdrawReqList?.map((item)=> 
                                    <WithdrawReqTable item={item}/>
                                    )}
                                    </tbody>
                                    </table>
                        </div>
                        </div>
                        <TablePagination totalPage={totalPage} path='/v2/withdrawrequest' />
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default WithdrawRequest;