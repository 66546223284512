import React, { useEffect } from "react";
import AdminNavbar from "../../../../components/global/Admin/Navbar";
import Sidebar from "../../../../components/global/Admin/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchUserDetail } from "../../../../Redux/UserSlice";
import Button from '@mui/material/Button';

const AdminUserDetails = ()=> {
    const userDetail = useSelector((state)=> state.user.details)
    const admin = JSON.parse(localStorage.getItem('taskbuzzarAdmin'))
    const dispatch = useDispatch()
    const {id} = useParams()

    useEffect(()=> {
        dispatch(fetchUserDetail({adminId: admin._id, userId : id}))
    },[])
    return (
        <>
         <div className="container flex flex-col h-screen">
            <AdminNavbar />
            <div className="main flex justify-between grow">
                <div className="w-1/5">
                    <Sidebar />
                </div>
                <div className="w-4/5 py-4 px-4 text-left">
                    <div className="rounded bg-gray-100 px-4 py-4">
                        <div className="w-3/5 bg-white py-4 px-4 rounded text-left">
                            <div className="flex justify-between items-center pb-2">
                                <h2 className="font-bold text-xl text-gray-700 pb-2">User details</h2>
                                <button className="py-1 px-2 text-gray-500 border border-gray-700 rounded">Edit</button>
                            </div>
                            <hr />
                            <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                <p className="font-bold text-gray-700 w-2/4">Id:</p>
                                <span>{userDetail?._id}</span>
                            </div>
                            <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                <p className="font-bold text-gray-700 w-2/4">Name:</p>
                                <span>{userDetail?.name}</span>
                            </div>
                            <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8">
                                <p className="font-bold text-gray-700 w-2/4">Email:</p>
                                <span>{userDetail?.email}</span>
                            </div>
                            <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                <p className="font-bold text-gray-700 w-2/4">Phone:</p>
                                <span>{userDetail?.mobileNumber}</span>
                            </div>
                            <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                <p className="font-bold text-gray-700 w-2/4">Address:</p>
                                <span>{userDetail?.address}</span>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
        </div>
        </>
    )
}
export default AdminUserDetails;