import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";

const renderPaginationItems = (totalPages, path) => {
  const params = new URLSearchParams(window.location.search);
  const currentPage = parseInt(params.get('page')) || 1;
  const paginationItems = [];

  // Calculate the range of buttons to display
  const startRange = Math.max(1, currentPage - 2);
  const endRange = Math.min(totalPages, startRange + 5);

  // Add "Previous" button
  if (currentPage > 1) {
    paginationItems.push(
      <li key="prev">
        <Link to={`${path}?page=${currentPage - 1}`}>&laquo; Prev</Link>
      </li>
    );
  }

  // Add numbered buttons
  for (let i = startRange; i <= endRange; i++) {
    paginationItems.push(
      <li key={i} className={currentPage === i ? 'active' : ''}>
        <Link to={`${path}?page=${i}`}>{i}</Link>
      </li>
    );
  }

  // Add "Next" button
  if (currentPage < totalPages) {
    paginationItems.push(
      <li key="next">
        <Link to={`${path}?page=${currentPage + 1}`}>Next &raquo;</Link>
      </li>
    );
  }

  return paginationItems;
};

const TablePagination =({ totalPage,path })=> {
  const [perPage, setPerPage] =  useState();
    return (
        <>
        <div className="pagination">
            <ul className="flex justify-end items-center gap-8">
          {
              renderPaginationItems(totalPage, path)
          }
          </ul>
          </div>
        </>
    )
}

export default TablePagination