import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import BaseUrl from '../Api/BaseUrl';

export const fetchOfferList = createAsyncThunk(
    'admin/offerList',
    async () => {
        const response = await fetch(`${BaseUrl}admin/offerList`);
        const responseData = await response.json();
        return responseData
      }
)
export const fetchTotalOffers = createAsyncThunk(
    'admin/totalOffer',
    async () => {
        const response = await fetch(`${BaseUrl}admin/totalOffer`);
        const responseData = await response.json();
        return responseData
      }
)
export const fetchOfferDetail = createAsyncThunk(
    'admin/offerdetails',
    async ({adminId, offerId}) => {
        const response = await fetch(`${BaseUrl}admin/offerdetails?adminId=${adminId}&offerId=${offerId}`);
        const responseData = await response.json();
        return responseData
      }
)
export const addOffer = createAsyncThunk(
    'admin/createOffer',
    async (formData) => {
        const response = await fetch(`${BaseUrl}admin/createOffer`, {
            method: 'POST',
            headers: {
                'accept': 'application/json'
            },
            body: formData
        });
        const responseData = await response.json();
        return responseData
      }
)
export const updateOffer = createAsyncThunk(
    'admin/updateOffer',
    async ({formData,offerId}) => {
        const response = await fetch(`${BaseUrl}admin/updateOffer?offerId=${offerId}`, {
          method: 'PUT',
          headers: {
              'accept': 'application/json'
          },
          body: formData
      });
        const responseData = await response.json();
        return responseData
      }
)
export const deleteOffer = createAsyncThunk(
    'admin/deleteOffer',
    async ({adminId,offerId}) => {
        const response = await fetch(`${BaseUrl}admin/deleteOffer?adminId=${adminId}&offerId=${offerId}`, {
          method: 'PUT',
          headers: {
              'accept': 'application/json'
          }
      });
        const responseData = await response.json();
        return responseData
      }
)

export const OfferSlice = createSlice({
    name: 'offer',
    initialState: {
        list: [],
        total: null,
        details: null,
        loading : false,
        error : null
      },

      reducers: {
    
      },

      extraReducers: (builder) => {
        // fetch builder
       builder.addCase(fetchOfferList.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(fetchOfferList.fulfilled, (state, action) => {
         state.list =  action.payload.responseResult;
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(fetchOfferList.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
        //  total builder
        builder.addCase(fetchTotalOffers.pending, (state, action) => {
          state.loading =  true;
        });
        builder.addCase(fetchTotalOffers.fulfilled, (state, action) => {
          state.total =  action.payload.responseResult;
          state.loading =  false;
          state.error =  false;
        });
        builder.addCase(fetchTotalOffers.rejected, (state, action) => {
          state.error =  true;
          state.loading =  false;
        });
        // Add builder
       builder.addCase(addOffer.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(addOffer.fulfilled, (state, action) => {
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(addOffer.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
        // fetch Detail builder
       builder.addCase(fetchOfferDetail.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(fetchOfferDetail.fulfilled, (state, action) => {
        state.details =  action.payload.responseResult;
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(fetchOfferDetail.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
        // update builder
       builder.addCase(updateOffer.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(updateOffer.fulfilled, (state, action) => {
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(updateOffer.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
        // delete builder
       builder.addCase(deleteOffer.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(deleteOffer.fulfilled, (state, action) => {
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(deleteOffer.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
    }
})

export default OfferSlice.reducer