import React from "react";

const TermsConditions = ()=> {
    return (
        <>
        <div className="header-subBanner">
            <div className="container px-4 md:px-18 h-full">
                <div className="">
                <h2 className="font-extrabold text-4xl text-sky-100 text-center">Terms and Conditions</h2>
                </div>
            </div>
        </div>
        <div className="py-10 px-4 md:px-10">
           <div className="text-left">
           <p className="text-gray-500 font-medium pt-4">Welcome to Task Bazzar! These Terms and Conditions ("Terms") govern your use of our website ( www.taskbazzar.com ) and the services we provide. By accessing or using our website and services, you agree to comply with these Terms. Please read them carefully.</p>

           <h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">Eligibility:</h4>
           <p className="text-gray-500 font-medium pt-4">To use our website and services, you must be at least 18 years old and have the legal capacity to enter into binding contracts. By accessing or using our website and services, you represent and warrant that you meet these eligibility requirements.</p>

<h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">User Account:</h4>
<p className="text-gray-500 font-medium pt-4">To access certain features of our website and services, you may need to create a user account. You are responsible for maintaining the confidentiality of your account information, including your username and password. You agree to provide accurate and complete information when creating your account and to update it promptly if any changes occur.</p>

<h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">User Conduct:</h4>
<p className="text-gray-500 font-medium pt-4">You agree to use our website and services in compliance with applicable laws and regulations. You shall not engage in any conduct that may disrupt or interfere with the functioning of our website or infringe upon the rights of others. You are solely responsible for any content you post or transmit through our website and shall not upload any content that is illegal, offensive, or violates the rights of any third party.</p>

<h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">Intellectual Property:</h4>
<p className="text-gray-500 font-medium pt-4">All content and materials available on our website, including but not limited to text, graphics, logos, images, and software, are the property of Task Bazzar or its licensors and are protected by intellectual property laws. You agree not to reproduce, distribute, modify, or create derivative works of any content without our prior written consent.</p>

<h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">Disclaimer of Warranties:</h4>
<p className="text-gray-500 font-medium pt-4">Our website and services are provided on an "as is" and "as available" basis, without any warranties or representations of any kind, whether express or implied. We do not guarantee the accuracy, completeness, or reliability of any content on our website or the availability of our services. You use our website and services at your own risk.</p>

<h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">Limitation of Liability:</h4>
<p className="text-gray-500 font-medium pt-4">To the fullest extent permitted by law, Task Bazzar and its affiliates, directors, employees, and agents shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our website and services.</p>

<h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">Governing Law and Dispute Resolution:</h4>
<p className="text-gray-500 font-medium pt-4">These Terms shall be governed by and construed in accordance with the laws of India. Any disputes arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts located in Jaipur, Rajasthan, India.</p>

<h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">Changes to Terms:</h4>
<p className="text-gray-500 font-medium pt-4">We reserve the right to update or modify these Terms at any time without prior notice. Any changes will be effective immediately upon posting on our website. It is your responsibility to review these Terms periodically to stay informed about any updates.

If you have any questions or concerns regarding these Terms, please contact us at info@taskbazzar.com

By using our website and services, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.</p>
           </div>
        </div>
        </>
    )
}

export default TermsConditions;