import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/global/Admin/Sidebar";
import AdminNavbar from "../../../components/global/Admin/Navbar";
import { Link, useNavigate } from "react-router-dom";
import BaseUrl from "../../../Api/BaseUrl";
import { toast } from "react-toastify";
import { deleteOffer, fetchOfferList } from "../../../Redux/OfferSlice";
import { useDispatch, useSelector } from "react-redux";

const Offer = ()=> {
    const admin = JSON.parse(localStorage.getItem('taskbuzzarAdmin'))
    const offerList = useSelector((state)=> state.offer.list)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const deleteItem = async (offer)=>{
        if(!window.confirm("Are you want to delete?")){
          return;
        }
        const deleteResponse = await dispatch(deleteOffer({
            adminId: admin._id,
            offerId: offer._id
        }));
          const res = deleteResponse.payload;
          if(res?.responseCode === 200){
            const offerListTRes = await dispatch(fetchOfferList(admin._id));
            console.log('offerList', offerListTRes)
            navigate('../offer')
            toast.success(res?.responseMessage)
          }else{
            toast.error(res?.responseMessage);
          }
      }
    useEffect(()=> {
        dispatch(fetchOfferList(admin._id))
    },[])
    return (
        <>
        <div className="container flex flex-col h-screen">
            <AdminNavbar />
            <div className="main flex justify-between grow">
                <div className="w-1/5">
                    <Sidebar />
                </div>
                <div className="w-4/5 py-4 px-4 text-left">
                    {/* <h2 className="font-bold text-2xl text-gray-800 pb-3">Offer</h2> */}
                    <div className="rounded bg-gray-100 px-4 py-4">
                        <div className="flex justify-between items-center">
                            <input type="text" placeholder="search" className="py-2 px-2 rounded" />
                            <button className="py-2 px-4 bg-sky-700 text-sky-100 hover:text-white rounded"> <Link to='../addoffer'><i className="fa-solid fa-plus"></i> Create offer</Link></button>
                        </div>
                        <div className="w-full bg-white py-2 px-2 rounded flex justify-between text-left gap-4 mt-4">
                        <div className="table w-full pt-4">
                                    <table class='shadow rounded w-full'>
                                    <thead class='sticky top-0'>
                                        <tr class='text-center'>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Id</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Offer Name</th>
                                            {/* <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Click Id</th> */}
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Category</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Payout</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Revenue</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {offerList?.map((item)=> <tr className='text-center justify-between' key={item._id}>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item._id}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2 text-sky-500 hover:underline"><Link to={`../offerdetails/${item._id}`}>{item.OfferName}</Link></td>
                                            {/* <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.clickId}</td> */}
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.category}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.payout}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.revenue}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2"><button className="py-2 px-2 rounded bg-red-400 text-white hover:bg-red-500 transition-all text-sm"  onClick={()=> deleteItem(item)}> Delete</button></td>
                                        </tr>)}
                                    </tbody>
                                    </table>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Offer;