import React from "react";
import chechImg from '../assets/images/check.png'

const PaymentProof = ()=> {
    return (
        <>
        <div className="header-subBanner">
            <div className="container px-4 md:px-18 h-full">
                <div className="flex justify-end items-center flex-col md:flex-row h-full">
                   
                </div>
            </div>
        </div>
        <div className="py-10 px-4 md:px-10">
            <div className=" flex justify-center payment-sec w-full mx-auto text-center">
                <img src={chechImg} alt="chechImg"/>
            </div>
            <div>
                <h2>Recent Payment Proofs Updated by taskbuzzar.com</h2>
            </div>
        </div>
        </>
    )
}

export default PaymentProof;