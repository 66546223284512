import './App.css';
import Navbar from './components/global/Navbar';
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from './pages/Home';
import Footer from './components/global/Footer';
import HowItWorks from './pages/HowItWorks';
import CreateAccount from './pages/CreateAccount';
import Login from './pages/Login';
import PaymentProof from './pages/PaymentProof';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './pages/userDashboard/Dashboard';
import Inbox from './pages/userDashboard/Inbox';
import Invite from './pages/userDashboard/Invite';
import Profile from './pages/userDashboard/Profile';
import AdminLogin from './pages/adminDashboard/Login';
import { useEffect, useState } from 'react';
import AdminDashboard from './pages/adminDashboard/Dashboard';
import Offer from './pages/adminDashboard/offer';
import Advertiser from './pages/adminDashboard/advertiser';
import Conversion from './pages/adminDashboard/conversion';
import Users from './pages/adminDashboard/user';
import { Provider } from 'react-redux';
import { store } from './Redux/store';
import Addadvertiser from './pages/adminDashboard/advertiser/create';
import OfferDetails from './pages/adminDashboard/offer/Detail';
import AddOffer from './pages/adminDashboard/offer/create';
import UserDetail from './components/UserDetail';
import AdminUserDetails from './pages/adminDashboard/user/Detail';
import ForgotPassword from './pages/adminDashboard/ForgotPassword';
import Contact from './pages/Contact';
import OfferDetailsUser from './pages/userDashboard/offer/Detail';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import WithdrawRequest from './pages/adminDashboard/withdrawRequest';
import EarningHistory from './pages/userDashboard/EarningHistory';
import UserWithdrawRequest from './pages/userDashboard/withdrawRequest';

function App() {
  // const [isAdminLogin, setIsAdminLogin] =  useState(false)
  // const adminLogin = JSON.parse(localStorage.getItem('taskbuzzarAdmin'))
  const currentPath = window.location.pathname;
  const isAdmin = currentPath.includes('/v2')
  // useEffect(()=> {
  //   if(adminLogin){
  //     setIsAdminLogin(true);
  //   }
  //   console.log('currentPath', currentPath.includes('/v2'))
  // },[])
  return (
    
    <div className="App">
      <ToastContainer />
      <Provider store={store}>
       <BrowserRouter>
       {!isAdmin  && <Navbar />}
      <Routes>
        <Route path='/'>
          <Route path="/" element={<Home />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/createaccount" element={<CreateAccount />} />
          <Route path="/login" element={<Login />} />
          <Route path="/paymentproof" element={<PaymentProof />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/inbox" element={<Inbox />} />
          <Route path="/earninghistory" element={<EarningHistory />} />
          <Route path="/withdrawrequest" element={<UserWithdrawRequest />} />
          <Route path="/inviteandearn" element={<Invite />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsconditions" element={<TermsConditions />} />
          <Route path="/offerdetails/:id" element={<OfferDetailsUser />} />
        </Route>

        {/* Admin routes */}
        <Route path='/v2'>
          <Route path='login' element={<AdminLogin />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path='dashboard' element={<AdminDashboard />} />
          <Route path='offer' element={<Offer />} />
          <Route path='advertiser' element={<Advertiser />} />
          <Route path='conversion' element={<Conversion />} />
          <Route path='user' element={<Users />} />
          <Route path='withdrawrequest' element={<WithdrawRequest />} />
          <Route path='addadvertiser' element={<Addadvertiser />} />
          <Route path='addoffer' element={<AddOffer />} />
          <Route path='offerdetails/:id' element={<OfferDetails />} />
          <Route path='userdetails/:id' element={<AdminUserDetails />} />
        </Route>
      </Routes>
      {!isAdmin && <Footer />}
    </BrowserRouter>
    </Provider>
    </div>
  );
}

export default App;
