import React, { useState } from "react";
import { Link } from "react-router-dom";

const ForgotPassword = ()=> {
    const [formData, setFormData] = useState(null)
    const handleInput = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e)=> {
        e.preventDefault()

    }
    return (
        <>
         <div className="main-form container h-full">
            <div className="login-wrapper text-left flex justify-center items-center h-screen">
        <div className="card w-2/5 mx-auto shadow py-4 px-4 ">
            <h2 className="page-title font-bold text-2xl text-sky-700 pb-4 text-center">Forgot Password</h2>
            <form className="pt-4">
                <div className="flex flex-col pb-4">
                <label htmlFor="username">Email</label>
                <input type="email" id="username" placeholder="Enter your username" name="email" value={formData?.email} onChange={(e)=>handleInput(e)}  className="py-2 px-2 border border-grey-300 rounded"/>
                </div>
                <div>
                </div>
                <div className="flex justify-between items-center">
                    <button type="submit" onClick={handleSubmit} className="py-2 px-6 bg-sky-700 rounded text-sky-100 hover:text-white">Reset Password</button>
                    <button className="py-2 px-2 hover:underline text-sky-500 hover:text-sky-700"><Link to='../login'><i class="fa-solid fa-lock text-gray-600"></i> Login</Link></button>
                </div>
            </form>
           </div>
           </div>
           </div>
        </>
    )
}

export default ForgotPassword;