import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/global/Admin/Sidebar";
import AdminNavbar from "../../components/global/Admin/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { fetchTotalOffers } from "../../Redux/OfferSlice";
import { fetchTotalUser } from "../../Redux/UserSlice";
import PostbackUrl from "../../components/PostbackUrl";

const AdminDashboard = ()=> {
    const admin = JSON.parse(localStorage.getItem('taskbuzzarAdmin'))
    const navigate = useNavigate();
    const totalOffer = useSelector((state)=> state.offer.total)
    const totalUser = useSelector((state)=> state.user.total)
    const dispatch = useDispatch()

    useEffect(()=>{
        if(!admin){
            navigate('../login')
        }
        dispatch(fetchTotalOffers())
        dispatch(fetchTotalUser())
    },[])
    return (
        <>
        <div className="container flex flex-col h-screen">
            <AdminNavbar />
            <div className="main flex justify-between grow">
                <div className="w-1/5">
                    <Sidebar />
                </div>
                <div className="w-4/5 py-4 px-4 text-left">
                    <h2 className="font-bold text-2xl text-gray-800 pb-3">Dashboard</h2>
                    <div className="rounded bg-gray-100 px-4 py-4">
                    <div className="flex gap-8 justify-between text-left">
                            <div className="w-full rounded bg-white">
                                <div className="py-1 px-2 bg-sky-400">
                                    <p className="text-base text-white">Total users:</p>
                                </div>
                                <div className="py-6 px-4 flex justify-between items-center">
                                    <h3 className="text-xl font-bold text-zinc-700">{totalUser}</h3>
                                    <i className="fa-solid fa-user text-sky-200 text-4xl"></i>
                                </div>
                            </div>
                            <div className="w-full rounded bg-white">
                                <div className="py-1 px-2 bg-sky-700">
                                    <p className="text-base text-white">Total offers:</p>
                                </div>
                                <div className="py-6 px-4 flex justify-between items-center">
                                    <h3 className="text-xl font-bold text-zinc-700">{totalOffer}</h3>
                                    <i className="fa-solid fa-signal text-blue-200 text-4xl"></i>
                                </div>
                            </div>
                            <div className="w-full rounded bg-white">
                                <div className="py-1 px-2 bg-orange-400">
                                    <p className="text-base text-white">Total advertiser</p>
                                </div>
                                <div className="py-6 px-4 flex justify-between items-center">
                                    <h3 className="text-xl font-bold text-zinc-700">20</h3>
                                    <i className="fa-solid fa-address-card text-orange-200 text-4xl"></i>
                                </div>
                            </div>
                        </div>
                        {/* <div className="bg-white py-2 px-2 mt-4">
                            <PostbackUrl />
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default AdminDashboard;