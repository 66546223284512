import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/global/Admin/Sidebar";
import AdminNavbar from "../../../components/global/Admin/Navbar";
import BaseUrl from "../../../Api/BaseUrl";
import { useDispatch } from "react-redux";
import { addUserWallet } from "../../../Redux/UserWalletSlice";

const Conversion = ()=> {
    const [conversion, setConversion] = useState([])
    const admin = JSON.parse(localStorage.getItem('taskbuzzarAdmin'))
    const dispatch = useDispatch()

    const fetchConversion = async()=> {
        const response = await fetch(`${BaseUrl}s/ConversionList?adminId=${admin._id}`)
        const resData = await response.json()
        // Check if the array is updated
        if (!arraysAreEqual(conversion, resData?.responseResult)) {
            const lastItemUserId = resData?.responseResult[resData?.responseResult?.length - 1].userId
            console.log('Array updated last item:',resData.responseResult[resData?.responseResult?.length - 1].userId);
            dispatch(addUserWallet({userId: lastItemUserId}))
          }
        setConversion(resData.responseResult);
    }
    const arraysAreEqual = (arr1, arr2) => {
        return JSON.stringify(arr1) === JSON.stringify(arr2);
      };
    useEffect(()=> {
        fetchConversion()
    },[])
    return (
        <>
        <div className="container flex flex-col h-screen">
            <AdminNavbar />
            <div className="main flex justify-between grow">
                <div className="w-1/5">
                    <Sidebar />
                </div>
                <div className="w-4/5 py-4 px-4 text-left">
                    {/* <h2 className="font-bold text-2xl text-gray-800 pb-3">Offer</h2> */}
                    <div className="rounded bg-gray-100 px-4 py-4">
                        <div className="w-full bg-white py-2 px-2 rounded flex justify-between text-left gap-4 mt-4">
                        <div className="table w-full pt-4">
                                    <table class='shadow rounded w-full'>
                                    <thead class='sticky top-0'>
                                        <tr class='text-left justify-between'>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Id</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">User</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Offer</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">TID</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Payout</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Revenue</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {conversion.map((item)=> <tr className='text-left justify-between' key={item._id}>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item._id}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">(ID: {item.userId}) {item.userName}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">(ID: {item.offerId}) {item.OfferName}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.tid}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.payout}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.revenue}</td>
                                        </tr>)}
                                    </tbody>
                                    </table>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Conversion;