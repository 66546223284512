import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserWithdrawRequest, fetchWithdrawRequest } from "../../../Redux/UserWalletSlice";
import WithdrawReqTable from "../../../components/WithdrawReqTable";
import UserDetail from "../../../components/UserDetail";
import WithdrawRequestModal from "../../../components/WithdrawRequestModal";

const UserWithdrawRequest = ()=> {
    const [isModal, setIsModal] = useState(false)
    const userId = JSON.parse(localStorage.getItem('loggedInUser'))._id
    const withdrawReqList = useSelector((state)=> state.userWallet.userWithdrawRequest)
    const dispatch = useDispatch()
    useEffect(()=> {
        dispatch(fetchUserWithdrawRequest(userId))
    },[])
    return (
        <>
       <div className="header-dashboardBanner">
            <div className="container px-4 md:px-18 h-full">
                <div className="flex justify-end items-center flex-col md:flex-row h-full">
                   
                </div>
            </div>
        </div>
        <div className="py-4 px-2 md:px-6 container user-dashboard">
            <p className="text-left pb-2 text-lg text-slate-500">Welcome to dashboard!</p>
            <div className="flex flex-col md:flex-row justify-between gap-4">
                <div className="w-full md:w-1/4">
                    <UserDetail />
                </div>
                <div className="w-full md:w-3/4">
                    {/* <h2 className="font-bold text-2xl text-gray-800 pb-3">Offer</h2> */}
                    <div className="shadow py-4 px-2 md:px-4 pb-4 md:pb-10 rounded-2xl text-center bg-gray-100">
                        <div className="py-4">
                            <div className="bg-white py-2 px-0 md:px-2 rounded text-left">
                                <div className="flex justify-end">
                                <button className="py-2 px-2 border bg-sky-700 rounded text-sky-100 hover:text-white" onClick={()=> setIsModal(!isModal)}><i className="fa-solid fa-money-bill-transfer"></i> Withdraw earnings</button>
                                </div>
                                <div className="px-2 pt-2 md:pt-2 overflow-x-auto">
                        <div className="table w-full pt-4">
                                    <table class='shadow rounded w-full'>
                                    <thead class='sticky top-0'>
                                        <tr class='text-left justify-between'>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Id</th>
                                            {/* <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">User Id</th> */}
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Amount</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Transection time</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Transection Id</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Payment method</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {withdrawReqList.map((item)=> 
                                    <tr key={item._id}>
                                        <td className="border border rounded-tl border-gray-300 py-2 px-2">{item._id}</td>
                                        {/* <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.userId}</td> */}
                                        <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.amount}</td>
                                        <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.transactionTime}</td>
                                        <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.transactionId}</td>
                                        <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.paytmNumber ? item.paytmNumber : item.upi}</td>
                                        <td className="border border rounded-tl border-gray-300 py-2 px-2 text-sm"><span className={item.status  === 'APPROVED' ? 'statusActive' : 'statusBlock'}>{item.status}</span></td>
                                    </tr>
                                    )}
                                    </tbody>
                                    </table>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            </div>
            {isModal && <WithdrawRequestModal isModal={isModal} setIsModal={setIsModal}/>}
            </div>
        </>
    )
}
export default UserWithdrawRequest;