import React, { useEffect, useRef, useState } from "react";
import AdminNavbar from "../../../../components/global/Admin/Navbar";
import Sidebar from "../../../../components/global/Admin/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchOfferDetail, updateOffer } from "../../../../Redux/OfferSlice";
import { toast } from "react-toastify";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { fetchAdvertiserList } from "../../../../Redux/AdvertiserSlice";

const OfferDetails = ()=> {
    const imageRef = useRef()
    const offerDetail = useSelector((state)=> state.offer.details)
    const advertiserList = useSelector((state)=> state.advertiser.list)
    const [formData, setFormData]  = useState({})
    const [isEditable, setIsEditable] =  useState(false);
    const [imageFile, setImageFile] = useState(formData?.OfferIcon);
    const admin = JSON.parse(localStorage.getItem('taskbuzzarAdmin'))
    const dispatch = useDispatch()
    const {id} = useParams()

    const handleSubmit = async(e)=> {
        e.preventDefault()
        const form = new FormData();
            form.append('adminId', admin._id);
            form.append('OfferIcon', imageFile);
            form.append('advertiserId', formData.advertiserId);
            form.append('OfferName', formData.OfferName);
            form.append('buttonName', formData.buttonName);
            form.append('category', formData.category);
            form.append('revenue', formData.revenue);
            form.append('payout', formData.payout);
            form.append('offerModel', formData.offerModel);
            form.append('OfferDetails', formData.OfferDetails);
            form.append('trackingUrl', formData.trackingUrl);
            form.append('offerId', id);
            console.log('formData', form)
            const updateOfferResponse = await dispatch(updateOffer({
                formData: form,
                offerId: id
            }));;
            const res = updateOfferResponse.payload;
                if(res?.responseCode === 200){
                    toast.success(res?.responseMessage)
                    setIsEditable(false)
                    dispatch(fetchOfferDetail({adminId: admin._id, offerId : id}))
                }else{
                    toast.error(res?.responseMessage || 'something went wrong');
                }
    }
    const handleInput= (e)=> {
        const FormData = {};
        const elementName = e.target.name;
        const elementValue = e.target.value;
        FormData[elementName] = elementValue;
        setFormData({...formData, ...FormData});
        console.log('imageRef', imageRef.current.value)    
    }
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        console.log("image",file)
        setImageFile(file);
      };

    useEffect(()=> {
        dispatch(fetchOfferDetail({adminId: admin._id, offerId : id}))
        dispatch(fetchAdvertiserList(admin._id))
        console.log('offerDetail',offerDetail)
        console.log('formData',formData)
    },[])
    useEffect(()=> {
        setFormData({...offerDetail,...formData})
    },[offerDetail])
    return (
        <>
         <div className="container flex flex-col h-screen">
            <AdminNavbar />
            <div className="main flex justify-between grow">
                <div className="w-1/5">
                    <Sidebar />
                </div>
                <div className="w-4/5 py-4 px-4 text-left">
                    <div className="rounded bg-gray-100 px-4 py-4">
                        <div className="w-full bg-white py-4 px-4 rounded text-left">
                        <form onSubmit={handleSubmit}>
                            <div className="flex justify-between items-center pb-2">
                                <h2 className="font-bold text-xl text-gray-700 pb-2">Offer details</h2>
                
                               {isEditable? <div className="">
                                            <button type="submit" className="py-1 px-2 text-gray-500 border bg-sky-700 rounded text-white">Save</button>
                                            <button type="button" className="py-1 px-2 text-gray-500 border border-gray-700 rounded ml-2" onClick={()=> setIsEditable(false)}>Cancel</button>
                                            </div>:
                                             <button type='button' className="py-1 px-2 text-gray-500 border border-gray-700 rounded" onClick={()=> setIsEditable(true)}>Edit</button>}
                            </div>
                            <hr />
                            
                                <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                    <p className="font-bold text-gray-700 w-2/4">Icon:</p>
                                    {!isEditable ? <img src={offerDetail?.OfferIcon} alt='offer icon' className="w-10" />: 
                                        <input type="file"className="py-2 px-2 border border-grey-300 rounded" ref={imageRef} 
                                            name="OfferIcon" 
                                            accept="image/*"
                                            onChange={handleImageChange}/>
                                    }
                                </div>
                                <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8">
                                    <p className="font-bold text-gray-700 w-2/4">Offer Name:</p>
                                    {!isEditable ? <span>{offerDetail?.OfferName}</span>:
                                        <input type="text" className="py-2 px-2 border border-grey-300 rounded" 
                                            name="OfferName" 
                                            value={formData?.OfferName} 
                                            onChange={handleInput}
                                            required/>
                                    }
                                </div>
                                <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                    <p className="font-bold text-gray-700 w-2/4">Advertiser Id:</p>
                                    {!isEditable ? <span>{offerDetail?.advertiserId}</span> :
                                         <select className="py-2 px-2 border border-grey-300 rounded"
                                         name="advertiserId"
                                         onChange={handleInput}
                                         value={formData?.advertiserId}
                                         >
                                             <option value="none" hidden>Select advertiser</option>
                                             {advertiserList ?.map((item)=> <option key={item.id} value={item._id}>{item._id}</option>)}
                                         </select>
                                    }
                                </div>
                                <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8">
                                    <p className="font-bold text-gray-700 w-2/4">Button Name:</p>
                                    {!isEditable ? <span>{offerDetail?.buttonName}</span> :
                                         <input type="text" className="py-2 px-2 border border-grey-300 rounded" 
                                         name="buttonName" 
                                         value={formData?.buttonName} 
                                         onChange={handleInput}
                                         required/>
                                    }
                                </div>
                                <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                    <p className="font-bold text-gray-700 w-2/4">Category:</p>
                                    {!isEditable ? <span>{offerDetail?.category}</span> :
                                        <input type="text" className="py-2 px-2 border border-grey-300 rounded" 
                                        name="category" 
                                        value={formData?.category} 
                                        onChange={handleInput}
                                        required/>
                                    }
                                </div>
                                <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8">
                                    <p className="font-bold text-gray-700 w-2/4">Offer Model:</p>
                                    {!isEditable ? <span>{offerDetail?.offerModel}</span> : 
                                        <input type="text" className="py-2 px-2 border border-grey-300 rounded" 
                                        name="offerModel" 
                                        value={formData?.offerModel} 
                                        onChange={handleInput}
                                        required/>
                                    }
                                </div>
                                <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                    <p className="font-bold text-gray-700 w-2/4">Payout:</p>
                                    {!isEditable ? <span>{offerDetail?.payout}</span> :
                                        <input type="text" className="py-2 px-2 border border-grey-300 rounded" 
                                        name="payout" 
                                        value={formData?.payout} 
                                        onChange={handleInput}
                                        required/>
                                    }
                                </div>
                                <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8">
                                    <p className="font-bold text-gray-700 w-2/4">Revenue:</p>
                                    {!isEditable ? <span>{offerDetail?.revenue}</span> :
                                        <input type="text" className="py-2 px-2 border border-grey-300 rounded" 
                                        name="revenue" 
                                        value={formData?.revenue} 
                                        onChange={handleInput}
                                        required/>
                                    }
                                </div>
                                <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                    <p className="font-bold text-gray-700 w-1/4">Offer Details:</p>
                                    {!isEditable ? <p className="w-3/4 show-detail"> <CKEditor
                                          editor={ClassicEditor}
                                          data={formData?.OfferDetails}
                                          onReady={(editor) => {
                                      }} /></p> :
                                        // <input type="text" className="py-2 px-2 border border-grey-300 rounded" 
                                        // name="OfferDetails" 
                                        // value={formData?.OfferDetails} 
                                        // onChange={handleInput}
                                        // required/>
                                        <CKEditor
                                          editor={ClassicEditor}
                                          data={formData?.OfferDetails}
                                          onReady={(editor) => {
                                      }}
                                      onChange={(event, editor) => {
                                      const data = editor.getData();
                                      const evnt = {
                                          target : {
                                              name : 'OfferDetails',
                                              value : data
                                          }};
                                          handleInput(evnt);
                                      }}
            />
                                    }
                                </div>
                                <div className="flex justify-start items-center py-2 px-2 mt-2 gap-8">
                                    <p className="font-bold text-gray-700 w-2/4">Tracking Url:</p>
                                    {!isEditable ? <span>{offerDetail?.trackingUrl}</span> :
                                        <textarea className="py-2 px-2 border border-grey-300 rounded" 
                                        name="trackingUrl" 
                                        value={formData?.trackingUrl} 
                                        onChange={handleInput}
                                        required/>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
        </div>
        </>
    )
}
export default OfferDetails