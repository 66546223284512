import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchOfferDetail } from "../../../../Redux/OfferSlice";
import UserDetail from "../../../../components/UserDetail";
import BaseUrl from "../../../../Api/BaseUrl";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const OfferDetailsUser = ()=> {
    const offerDetail = useSelector((state)=> state.offer.details)
    const admin = JSON.parse(localStorage.getItem('taskbuzzarAdmin'))
    const user = JSON.parse(localStorage.getItem('loggedInUser'))
    const dispatch = useDispatch()
    const {id} = useParams()

    useEffect(()=> {
        dispatch(fetchOfferDetail({adminId: 1, offerId : id}))
        console.log('offerDetail', offerDetail)
    },[])
    return (
        <>
         <div className="header-dashboardBanner">
            <div className="container px-4 md:px-18 h-full">
                <div className="flex justify-end items-center flex-col md:flex-row h-full">
                   
                </div>
            </div>
        </div>
        <div className="py-4 px-2 md:px-6 container user-dashboard">
            <p className="text-left pb-2 text-lg text-slate-500">Welcome to dashboard!</p>
            <div className="flex flex-col md:flex-row justify-between gap-4">
                <div className="w-full md:w-1/4">
                    <UserDetail />
                </div>
                <div className="w-full md:w-3/4">
                    <div className="shadow py-4 sm:px-4 px-2 pb-4 md:pb-10 rounded-2xl text-center bg-gray-100">
                    <div className="w-full md: w-3/5 bg-white sm:py-4 py-2 sm:px-4 px-2 rounded text-left offerDetails">
                            <div className="flex justify-between pb-2">
                                <h2 className="font-bold text-xl text-gray-700 pb-2">Offer details</h2>
                                {/* <button className="py-1 px-2 text-gray-500 border border-gray-700 rounded">Edit</button> */}
                            </div>
                            <hr />
                            <div className="flex justify-start py-2 px-2 mt-2 gap-8">
                                <p className="font-bold text-gray-700 w-2/4">Icon:</p>
                                <img src={offerDetail?.OfferIcon} alt='offer icon' className="w-20" />
                            </div>
                            <div className="flex justify-start py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                <p className="font-bold text-gray-700 w-2/4">Offer Name:</p>
                                <span>{offerDetail?.OfferName}</span>
                            </div>
                            {/* <div className="flex justify-start py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                <p className="font-bold text-gray-700 w-2/4">Advertiser Id:</p>
                                <span>{offerDetail?.advertiserId}</span>
                            </div> */}
                            <div className="flex justify-start py-2 px-2 mt-2 gap-8">
                                <p className="font-bold text-gray-700 w-2/4">Button Name:</p>
                                <span>{offerDetail?.buttonName}</span>
                            </div>
                            <div className="flex justify-start py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                <p className="font-bold text-gray-700 w-2/4">Category:</p>
                                <span>{offerDetail?.category}</span>
                            </div>
                            {/* <div className="flex justify-start py-2 px-2 mt-2 gap-8">
                                <p className="font-bold text-gray-700 w-2/4">Offer Model:</p>
                                <span>{offerDetail?.offerModel}</span>
                            </div> */}
                            <div className="flex justify-start py-2 px-2 mt-2 gap-8">
                                <p className="font-bold text-gray-700 w-2/4">Payout:</p>
                                <span>{offerDetail?.payout}</span>
                            </div>
                            <div className="flex justify-start sm:flex-row flex-col py-2 px-2 mt-2 gap-8 bg-gray-50 rounded">
                                <p className="font-bold text-gray-700 sm:w-1/4">Offer Details:</p>
                                <p className="sm:w-3/4 show-detail"> <CKEditor
                                          editor={ClassicEditor}
                                          data={offerDetail?.OfferDetails}
                                          onReady={(editor) => {
                                      }} /></p>
                            </div>
                            <div className="flex justify-start py-2 px-2 mt-2 gap-8">
                                <p className="font-bold text-gray-700 w-2/4"> Action:</p>
                                <p className="cursor-pointer hover:text-sky-700 w-2/4"><Link to={`${BaseUrl}clickData/click?u=${user._id}&o=${id}`} target="_blank" className="bg-green-400 rounded-xl text-white py-1 px-2">{offerDetail?.buttonName}</Link></p>
                            </div>
                        </div>
                            </div>
                            </div>
                            </div>
                            </div>
        </>
    )
}
export default OfferDetailsUser