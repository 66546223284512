import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import mobileScreen from '../assets/images/mobileScreen.png'
import icon1 from '../assets/images/icon1.png'
import icon2 from '../assets/images/icon2.png'
import icon3 from '../assets/images/icon3.png'
import icon4 from '../assets/images/icon4.png'
import icon5 from '../assets/images/icon5.png'
import icon6 from '../assets/images/icon6.png'
import Counter from "../components/Counter";
import AboutSec from "../components/AboutSec";
import { Link } from "react-router-dom";
import Brands from "../components/Brands";
import Typewriter from "typewriter-effect";

const Home =()=> {
    const typewriterOptions = {
        strings: ['START EARNING UPTO 1 LAKH EVERY MONTH', 'START EARNING UPTO 1 LAKH EVERY MONTH'],
        autoStart: true,
        loop: true,
        delay: 150,
        cursor: '|', 
      };
    return (
        <>
        <div className="home-container">
        {/* <Swiper navigation={true} 
                modules={[Autoplay ,Navigation]} loop={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false
                }} className="mySwiper">
            <SwiperSlide><img src={slideImg} alt="slide-img" className="main-banner"/>
            <div className="banner-content">
                <h2 className="font-bold text-white text-4xl pb-2">GET 99 FOR SIGNUP</h2>
                <p className="text-lg text-sky-100">Registration at taskbuzzar.com is free and rewarding. You will get 99 on successful registration. Amount will be credited to your apnatask.com wallet.</p>
                <button className="py-2 px-4 bg-sky-800 text-sky-100 hover:text-white rounded mt-4"><Link to='createaccount'>Sign Up <i className="fa-solid fa-angles-right"></i></Link></button>
            </div>
            </SwiperSlide>
            <SwiperSlide><img src={slideImg} alt="slide-img" className="main-banner"/>
            <div className="banner-content">
                <h2 className="font-bold text-white text-4xl pb-2">GET 0.1 DAILY FOR LOGIN</h2>
                <p className="text-lg text-sky-100">If you already have apnatask.com account, just login to your account and get your daily login bonus. Login bonus is limited to once a day.</p>
                <button className="py-2 px-4 bg-sky-800 text-sky-100 hover:text-white rounded mt-4"><Link to='login'>Login Now <i className="fa-solid fa-angles-right"></i></Link></button>
            </div>
            </SwiperSlide>
            <SwiperSlide><img src={slideImg} alt="slide-img" className="main-banner"/>
            <div className="banner-content">
                <h2 className="font-bold text-white text-4xl pb-2">GET 3 FOR EVERY FRIEND YOU INVITE</h2>
                <p className="text-lg text-sky-100">You earning is just not limited to emails activity. You can also earning thousands of rupees daily by inviting your friend. You will get paid when your friend joins apnatask.com using your referral link.</p>
                <button className="py-2 px-4 bg-sky-800 text-sky-100 hover:text-white rounded mt-4"><Link to='createaccount'>Login Now <i className="fa-solid fa-angles-right"></i></Link></button>
            </div>
            </SwiperSlide>
        </Swiper> */}
        <div className="header-banner h-screen">
            <div className="container px-4 md:px-18 h-full">
                <div className="flex justify-end items-center flex-col md:flex-row h-full">
                    <div className="w-full md:w-2/5 md:absolute bottom-0 left-0 md:pl-14 mx-auto">
                        <img src={mobileScreen} alt="mobileScreen" className="w-4/5 mx-auto" />
                    </div>
                    <div className="w-full md:w-3/5 mt-8">
                        <div className="banner-heading pb-4">
                            <span className="text-3xl uppercase">
                            <Typewriter options={typewriterOptions} />
                            </span>
                        </div>
                        <div className="banner-links flex justify-center gap-4 mt-4">
                            <Link to='/login' className="py-2 px-4 rounded bg-gray-50 text-black text-xl font-medium">Login Here</Link>
                            <Link to='/createaccount' className="py-2 px-4 rounded bg-gray-200 text-black text-xl font-medium">Signup Now</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* <div className="px-10 bg-gray-50">
            <div className="signup py-10">
                <div className="flex flex-wrap items-center justify-between">
                    <div className="w-4/6 text-left">
                        <h2 className="font-bold text-3xl pb-4 text-gray-700">Send mails from <span className="">taskbuzzar.com</span> and reply to get paid</h2>
                        <p className="text-neutral-500 text-base">You will get hundreds of paid emails in your taskbuzzar.com account daily and you can earn upto ₹5 from each mail activity.</p>
                    </div>
                    <div className="w-2/6">
                        <button className="py-2 px-4 bg-sky-600 text-white rounded"><Link to='createaccount'>SignUp Now <i className="fa-solid fa-chevron-right"></i> </Link></button>
                    </div>
                </div>
            </div>
        </div> */}
        <section className="px-4 md:px-20 py-14 md:py-20">
            <div className="contant w-full mx-auto">
                <h2 className="font-bold md:font-extrabold text-3xl md:text-4xl pb-4 text-gray-800 text-center">HOW TO WORK AND EARN ?</h2>
            </div>
            {/* <div className="flex flex-wrap items-center justify-between py-8 pt-12">
                <div className="w-1/4">
                    <div className="icon-box">
                        <div className="step-icon bg-sky-50">
                            <i className="fa-solid fa-lock text-5xl text-sky-700"></i>
                        </div>  
                        <h5 className="pt-4 text-zinc-600 text-lg">Login Daily</h5>
                    </div>
                   </div>
                   <div className="w-1/4">
                   <div className="icon-box">
                   <div className="step-icon bg-sky-50">
                        <i className="fa-solid fa-inbox text-5xl text-sky-700"></i>
                        </div>
                        <h5 className="pt-4 text-zinc-600 text-lg">Check emails</h5>
                    </div>
                   </div>
                   <div className="w-1/4">
                   <div className="icon-box">
                   <div className="step-icon bg-sky-50">
                        <i className="fa-solid fa-reply-all text-5xl text-sky-700"></i>
                        </div>
                        <h5 className="pt-4 text-zinc-600 text-lg">Send emails & reply back</h5>
                    </div>
                   </div>
                   <div className="w-1/4">
                    <div className="icon-box">
                    <div className="step-icon bg-sky-50">
                        <i className="fa-solid fa-money-bill-1 text-5xl text-sky-700"></i>
                        </div>
                        <h5 className="pt-4 text-zinc-600 text-lg">Get Paid Daily</h5>
                    </div>
                </div>
            </div> */}
            <div className="flex flex-col md:flex-row justify-between gap-4 text-left pt-8 items-center">
                <div className="w-full md:w-1/2 flex flex-col md:flex-row justify-between gap-2 md:gap-6 items-center">
                    <div className="w-full md:w-1/3">
                        <img src={icon1} alt="iconImg" className="rounded-full"/>
                    </div>
                    <div className="w-full md:w-2/3">
                        <h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">1. Register</h4>
                        <p className="text-gray-500 font-medium pt-4">Getting started is easy. Simply download the Task Bazzar app or
                                    continue on the web, and create your account. We'll guide you through the process,
                                    ensuring a smooth and hassle-free registration.</p>
                    </div>
                </div>
                <div className="w-full md:w-1/2 flex flex-col md:flex-row justify-between gap-6 items-center">
                    <div className="w-full md:w-1/3">
                        <img src={icon2} alt="iconImg" className="rounded-full"/>
                    </div>
                    <div className="w-full md:w-2/3">
                        <h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">2. Select Task</h4>
                        <p className="text-gray-500 font-medium pt-4">Explore a wide range of tasks available on our platform. With various
                                    options to choose from, you can select tasks that align with your skill set and
                                    interests. We believe in leveraging your strengths to maximize your earning potential.</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between gap-4 text-left pt-8 items-center">
                <div className="w-full md:w-1/2 flex flex-col md:flex-row justify-between gap-6 items-center">
                    <div className="w-full md:w-1/3">
                        <img src={icon3} alt="iconImg" className="rounded-full"/>
                    </div>
                    <div className="w-full md:w-2/3">
                        <h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">3. Get Training</h4>
                        <p className="text-gray-500 font-medium pt-4">Enhance your skills and become an expert in your chosen field. We
                                    offer convenient training programs that you can enroll in at your preferred time. Our
                                    training modules are designed to equip you with the knowledge and tools necessary to
                                    excel in your tasks.</p>
                    </div>
                </div>
                <div className="w-full md:w-1/2 flex flex-col md:flex-row justify-between gap-6 items-center">
                    <div className="w-full md:w-1/3">
                        <img src={icon4} alt="iconImg" className="rounded-full"/>
                    </div>
                    <div className="w-full md:w-2/3">
                        <h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">4. Start Working</h4>
                        <p className="text-gray-500 font-medium pt-4">Once you've selected a task and completed the training, you're ready
                                    to start working. Whether it's onboarding customers for different platforms or
                                    performing other tasks, you have the freedom to work on projects that suit your
                                    expertise and interests.</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between gap-4 text-left pt-8 items-center">
                <div className="w-full md:w-1/2 flex flex-col md:flex-row justify-between gap-6 items-center">
                    <div className="w-full md:w-1/3">
                        <img src={icon5} alt="iconImg" className="rounded-full"/>
                    </div>
                    <div className="w-full md:w-2/3">
                        <h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">5. Earning</h4>
                        <p className="text-gray-500 font-medium pt-4">At Task Bazzar, we value your hard work and dedication. After you
                                    complete a task, our team will verify your work and credit your earnings. Typically, you
                                    can expect your earnings to be credited within 48 hours, ensuring a prompt and reliable
                                    payment process.</p>
                    </div>
                </div>
                <div className="w-full md:w-1/2 flex flex-col md:flex-row justify-between gap-6 items-center">
                    <div className="w-full md:w-1/3">
                        <img src={icon6} alt="iconImg" className="rounded-full"/>
                    </div>
                    <div className="w-full md:w-2/3">
                        <h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">6. Support</h4>
                        <p className="text-gray-500 font-medium pt-4">We believe in providing excellent support to our users. Our support
                                    team is available from 6 AM to 12 PM, ready to assist you with any questions or concerns
                                    you may have. Whether through chat or call, we're here to ensure your experience with
                                    Task Bazzar is smooth and enjoyable.</p>
                    </div>
                </div>
            </div>
        </section>
        <Counter />
        <AboutSec />
        <Brands />
        </div>
        </>
    )
}

export default Home;