import React, { useState } from "react";
import AdminNavbar from "../../../../components/global/Admin/Navbar";
import Sidebar from "../../../../components/global/Admin/Sidebar";
import { useDispatch } from "react-redux";
import { addAdvertiser } from "../../../../Redux/AdvertiserSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Addadvertiser = ()=> {
    const [formData, setFormData] = useState({})
    const admin = JSON.parse(localStorage.getItem('taskbuzzarAdmin'))
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleInput = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value, adminId: admin._id})
    }
    const handleLogin = async (e)=> {
        e.preventDefault();
        const advertiserRes = await dispatch(addAdvertiser(formData));
        const res = advertiserRes.payload 
        if(res.responseCode === 200){
            toast.success(res.responseMessage)
            navigate('../advertiser');
        }else{
            toast.error(res.responseMessage)
        }
        console.log('advertiserRes', res)
    }
    return (
        <>
        <div className="container flex flex-col h-screen">
            <AdminNavbar />
            <div className="main flex justify-between grow">
                <div className="w-1/5">
                    <Sidebar />
                </div>
                <div className="w-4/5 py-4 px-4 text-left">
                    <div className="rounded bg-gray-100 px-4 py-4">
                        <div className="w-3/5 bg-white py-2 px-2 rounded text-left">
                        <h2 className="font-bold text-xl text-gray-700 pb-4">Create advertiser</h2>
                            <form>
                                <div className="flex justify-between items-center gap-4">
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="username">Name</label>
                                        <input type="text" id="username" name="fullName" value={formData?.fullName} onChange={(e)=>handleInput(e)}  className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div>
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" id="email" name="email" value={formData?.email} onChange={(e)=>handleInput(e)}  className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div> 
                                </div>
                                <div className="flex justify-between items-center gap-4">
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="mobileNumber">Phone</label>
                                        <input type="text" id="mobileNumber" name="mobileNumber" value={formData?.mobileNumber} onChange={(e)=>handleInput(e)}  className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div>
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="company">Comapny</label>
                                        <input type="text" id="company" name="companyName" value={formData?.companyName} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div> 
                                </div>
                                <div className="flex justify-between items-center">
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="address">Address</label>
                                        <input type="text" id="address" name="address" value={formData?.address} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div> 
                                </div>
                                <div className="flex justify-between items-center gap-4">
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="password">Password</label>
                                        <input type="password" id="password" placeholder="Enter your password" name="password" value={formData?.password} onChange={(e)=>handleInput(e)}  className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div>
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="confirm_password">Confirm password</label>
                                        <input type="password" id="confirm_password" name="confirm_password" value={formData?.confirm_password} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div> 
                                </div>
                            <button type="submit" onClick={handleLogin} className="py-2 px-4 bg-sky-700 rounded text-sky-100 hover:text-white">Submit</button>
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
        </div>
        </>
    )
}

export default Addadvertiser;