import React, { useEffect, useState } from "react";
import money from '../../assets/images/online-earning.png'
import { Link } from "react-router-dom";
import UserDetail from "../../components/UserDetail";
import { fetchUserWallet, withdrawAmountReq } from "../../Redux/UserWalletSlice";
import { useDispatch, useSelector } from "react-redux";
import WithdrawRequestModal from "../../components/WithdrawRequestModal";

const Dashboard = ()=>{
    const user = JSON.parse(localStorage.getItem('loggedInUser'))
    const wallet = useSelector((state)=> state.userWallet.wallet)
    const dispatch = useDispatch()

    useEffect(()=> {
        dispatch(fetchUserWallet(user._id))
    },[])
    return (
        <>
        <div className="header-dashboardBanner">
            <div className="container px-4 md:px-18 h-full">
                <div className="flex justify-end items-center flex-col md:flex-row h-full">
                   
                </div>
            </div>
        </div>
        <div className="py-4 px-2 md:px-6 container user-dashboard">
            <p className="text-left pb-2 text-lg text-slate-500">Welcome to dashboard!</p>
            <div className="flex flex-col md:flex-row justify-between gap-4">
                <div className="w-full md:w-1/4">
                    <UserDetail />
                </div>
                <div className="w-full md:w-3/4">
                    <div className="shadow py-4 px-2 md:px-4 pb-10 rounded-2xl text-center bg-gray-100">
                        <div className="flex flex-col md:flex-row gap-8 justify-between text-left">
                            <div className="w-full rounded bg-white">
                                <div className="py-1 px-2 bg-sky-400">
                                    <p className="text-base text-white">Current Balance:</p>
                                </div>
                                <div className="py-6 px-4 flex justify-between items-center">
                                    <h3 className="text-xl font-bold text-zinc-700">₹ {wallet}.00</h3>
                                    <i className="fa-solid fa-file-invoice-dollar text-sky-200 text-4xl"></i>
                                </div>
                            </div>
                            <div className="w-full rounded bg-white">
                                <div className="py-1 px-2 bg-sky-700">
                                    <p className="text-base text-white">Pending Amounts:</p>
                                </div>
                                <div className="py-6 px-4 flex justify-between items-center">
                                    <h3 className="text-xl font-bold text-zinc-700">Worth ₹ 00.00</h3>
                                    <i className="fa-solid fa-envelope-open-text text-blue-200 text-4xl"></i>
                                </div>
                            </div>
                            <div className="w-full rounded bg-white">
                                <div className="py-1 px-2 bg-orange-400">
                                    <p className="text-base text-white">Refer & Earn</p>
                                </div>
                                <div className="py-6 px-4 flex justify-between items-center">
                                    <h3 className="text-xl font-bold text-zinc-700">₹ 3/Referral</h3>
                                    <i className="fa-solid fa-address-card text-orange-200 text-4xl"></i>
                                </div>
                            </div>
                        </div>
                        <div className="py-4">
                            <div className="w-full bg-white py-2 px-2 rounded flex flex-col md:flex-row justify-between text-left gap-8">
                                <div className="px-2 pt-10">
                                <p className="text-xl md:text-2xl text-gray-600">Simply send emails to yourself, reply back and earn money.</p>
                                <div className="flex flex-col md:flex-row justify-start pt-10 gap-2 md:gap-20">
                                    <button className="bg-sky-700 py-2 px-3 rounded text-white"><Link to='/inbox'><i className="fa-solid fa-hand-holding-dollar"></i> Earn more</Link></button>
                                    <button className="py-2 px-2 border boredr-sky-700 rounded text-gray-600"><Link to='/withdrawrequest'><i className="fa-solid fa-money-bill-transfer"></i> Withdraw earnings</Link></button>
                                </div>
                                </div>
                                <img src={money} alt='moneyImg' className="w-full md:w-1/3"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Dashboard;