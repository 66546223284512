import React from "react";
import onlineImg from '../assets/images/earn-money-online.jpg'
import registrationImg from '../assets/images/registration.png'
import emailImg from '../assets/images/email.png'
import friendsImg from '../assets/images/friends.png'
import moneyImg from '../assets/images/money.png'
import { Link } from "react-router-dom";
import mobileScreen from '../assets/images/mobileScreen.png'

const HowItWorks = ()=> {
    return (
        <>
        <div className="header-subBanner">
            <div className="container px-4 md:px-18 h-full">
                <div className="flex justify-end items-center flex-col md:flex-row h-full">
                   
                </div>
            </div>
        </div>
            <div className="py-10 px-4 md:px-10">
                <div className="flex flex-col md:flex-row justify-between text-left gap-6 pb-6">
                    <div className="w-full md:w-1/2">
                    <h2 className="font-bold text-3xl md:text-4xl text-neutral-700 mb-3 py-1">Earn Money Online <small>taskbuzzar.com</small></h2>
                    <p className="text-neutral-500 font-light text-sm md:text-base">
						Now a days mobile phones has become a part of everyone's lifestyle and people tend to spend much time on phone in their daily life. But without doing anything on phone that add benefits to your life is just waste of time. Some of people used to earn money online by using their mobile. You can also earn money instantly by checking and replying few mails that you will get everyday on taskbuzzer.com. Very soon will be adding more features for online income.
					</p>
                    </div>
                    <div className="w-full md:w-1/2">
                        <img src={onlineImg} alt='earn-money-online' className="online-money rounded-xl"/>
                    </div>
                </div>
            </div>
            <div className="py-10">
                <div className="sec-heading pb-4">
                    <h2 className="font-bold text-3xl md:text-4xl text-neutral-700">How it works?</h2>
                </div>
                <div className="px-4 md:px-20 bg-gray-50">
                <div className="flex flex-col md:flex-row py-10 gap-4 text-left">
                    <div className="w-full md:w-1/2">
                        <h3 className="font-bold text-2xl md:text-3xl text-blue-900 pb-2">1. Register and Get ₹99</h3>
                        <small className="text-base text-neutral-600">Login to your account daily to earn more</small>
                    </div>
                    <div className="w-full md:w-1/2 text-left">
                        <div className="step-icon mb-2">
                        <img src={registrationImg} alt="registrationImg" />
                        </div>
                        <p className="text-neutral-600 font-light text-sm md:text-base pb-4">Registration is also rewarding on mailrupee.com, if you haven't registered yet <Link to='/'>click here</Link> to register and login to start earning money online by just sending mail from mailrupee.com and replying them back.
                        <br />
                        Once you reach your payment threshold, you can redeem you earnings. mailrupee.com also pays 0.1 for your login everyday.</p>
                        <button className="py-2 px-4 bg-red-600 text-white ">SignUp Now <i className="fa-solid fa-chevron-right"></i> </button>
                    </div>
                </div>
                <div className="flex flex-col-reverse md:flex-row py-10 gap-4 text-left">
                    <div className="w-full md:w-1/2 text-left">
                        <div className="step-icon mb-2">
                        <img src={emailImg} alt="emailImg" />
                        </div>
                        <p className="text-neutral-600 font-light text-sm md:text-base pb-4">
						After your successful registration you will start getting paid emails to your mailrupee.com inbox. You will get payout for each mail you send to yourself and reply back. Amount will be credited to your account when you will click on the mail after replying back.
					</p>
                        {/* <button className="py-2 px-4 bg-red-600 text-white ">SignUp Now <i className="fa-solid fa-chevron-right"></i> </button> */}
                    </div>
                    <div className="w-full md:w-1/2">
                        <h3 className="font-bold text-2xl md:text-3xl text-blue-900 pb-2">2. Send Mail and Reply</h3>
                        <small className="text-base text-neutral-600">Check your mailrupee.com inbox daily</small>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row py-10 gap-4 text-left">
                    <div className="w-full md:w-1/2">
                        <h3 className="font-bold text-2xl md:text-3xl text-blue-900 pb-2">3. Invite Friends</h3>
                        <small className="text-base text-neutral-600">Get ₹3 for each friend you invite.</small>
                    </div>
                    <div className="w-full md:w-1/2 text-left">
                        <div className="step-icon mb-2">
                        <img src={friendsImg} alt="friendsImg" />
                        </div>
                        <p className="text-neutral-600 font-light text-sm md:text-base pb-4">You earning is just not limited to emails activity. You can also earning thousands of rupees daily by inviting your friend. You will get paid when your friend SignUp mailrupee.com using your referral link.
                        <br />
                        If you have your own website and blog then you can promote your referral link there as well.</p>
                        <button className="py-2 px-4 bg-white text-current ">You invite your friend  <i className="fa-solid fa-arrow-right"></i> Your friend will get ₹99 and you will get ₹3 </button>
                    </div>
                </div>
                <div className="flex flex-col-reverse md:flex-row py-10 gap-4 text-left">
                    <div className="w-full md:w-1/2 text-left">
                        <div className="step-icon mb-2">
                        <img src={moneyImg} alt="moneyImg" />
                        </div>
                        <p className="text-neutral-600 font-light text-base pb-4">
						When you reach the minimum threshold(2500 ) in your mailrupee.com wallet and you can transfer this money to your paytm wallet, freecharge wallet, phonePe wallet and bank account.
                        <br/>
                        Once you receive your earned income into your personal wallet or bank account, you can use it as per your need.
					</p>
                        {/* <button className="py-2 px-4 bg-red-600 text-white ">SignUp Now <i className="fa-solid fa-chevron-right"></i> </button> */}
                    </div>
                    <div className="w-full md:w-1/2">
                        <h3 className="font-bold text-2xl md:text-3xl text-blue-900 pb-2">4. Redeem your money</h3>
                        <small className="text-base text-neutral-600">Paytm, freecharge, phonePe and bank account</small>
                    </div>
                </div>
                </div>
                </div>
        </>
    )
}
export default HowItWorks;