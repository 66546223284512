import React from "react";

const PrivacyPolicy = ()=> {
    return (
        <>
        <div className="header-subBanner">
            <div className="container px-4 md:px-18 h-full">
                <div className="">
                   <h2 className="font-extrabold text-4xl text-sky-100 text-center">Privacy Policy</h2>
                </div>
            </div>
        </div>
        <div className="py-10 px-4 md:px-10">
            <div className="text-left">
            <p className="text-gray-500 font-medium pt-4"> At Task Bazzar, we value the privacy and security of our users. This Privacy Policy explains how we collect, use, and protect your personal information when you register on our website and use our services. By accessing or using our website and services, you agree to the terms of this Privacy Policy.</p>

            <h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">Information Collection and Use:</h4>

            <p className="text-gray-500 font-medium pt-4">We may collect personal information such as your name, email address, contact number, and other relevant details when you register on our website or use our services. This information is used to provide you with a personalized experience, improve our services, and communicate with you regarding updates, promotions, and other relevant information.</p>

<h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">Information Sharing:</h4>

<p className="text-gray-500 font-medium pt-4">We may share your personal information with trusted third-party service providers who assist us in operating our website and delivering our services. These service providers are obligated to maintain the confidentiality of your information and are prohibited from using it for any other purpose.</p>

<h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">Cookies and Tracking Technologies:</h4>

<p className="text-gray-500 font-medium pt-4">We use cookies and similar tracking technologies to enhance your browsing experience and collect information about how you interact with our website. This information helps us analyze trends, administer the website, and gather demographic information for targeted marketing purposes. You can choose to disable cookies through your browser settings, although this may affect certain functionalities of our website.</p>

<h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">Data Security:</h4>

<p className="text-gray-500 font-medium pt-4">We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your information.</p>

<h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">Device ID:</h4>

<p className="text-gray-500 font-medium pt-4">Our application utilizes a third-party library for user management and analytics, which involves the collection of unique device IDs. To ensure the accuracy of our analytics and prevent data duplication, only one request for a specific device ID will be created at a time. We want to assure you that we take the privacy and security of your information seriously. The collected device IDs are used solely for the purpose of enhancing user experience and improving our services. We do not share this information with third parties. You have the right to control the use of your device ID, and options for opting out of analytics tracking or requesting the deletion of your device ID are available. Our privacy policy may be updated from time to time, and we will notify you of any material changes.

Protecting End User Privacy with Branch’s SDK Privacy Controls</p>

<p className="text-gray-500 font-medium pt-4">We take our customers’ privacy needs very seriously. To enable our customers to honor opt-out requests from their end-users or comply with laws that allow end users to restrict processing of their data, we provide mechanisms for disabling certain data collection features, which are identified below. This way, if a user indicates that they want to limit collection of their data on your app or website, or if you otherwise determine that a particular end user’s data should not be collected or otherwise processed, you can continue to make use of the Branch SDK (e.g. for creating and sharing Branch links to deep link end users) while not measuring attribution for that end user.

When to Use the SDK Privacy Controls</p>

<p className="text-gray-500 font-medium pt-4">We collect limited device information to power our deep linking technology and to provide attribution and analytics services. You can learn more about the types of data that we need to collect through our services here: https://branch.io/policies/#privacy. We understand that some end-users would like to opt out of (or not opt into) this data processing. In other cases, legal requirements (or Branch’s policies) do not permit that certain types of personal data for certain end users be provided to Branch (for example, data relating to children under the age of 13). To address these needs, we have developed the SDK Privacy Controls feature.</p>

<h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">Links to Third-Party Websites:</h4>

<p className="text-gray-500 font-medium pt-4">Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of any third-party websites before providing any personal information.</p>

<h4 className="font-bold text-gray-800 my-4 text-xl md:text-2xl">Updates to Privacy Policy:</h4>

<p className="text-gray-500 font-medium pt-4">We reserve the right to update or modify this Privacy Policy at any time. Any changes will be posted on this page, and the updated Privacy Policy will be effective immediately upon posting. We encourage you to review this Privacy Policy periodically to stay informed about how we collect, use, and protect your personal information.</p>

 

<p className="text-gray-500 font-medium pt-4">If you have any questions or concerns regarding this Privacy Policy or our privacy practices, please contact us at info@taskbazzar.com

By using our website and services, you consent to the terms of this Privacy Policy and acknowledge that you have read and understood its contents.</p>
            </div>
        </div>
        </>
    )
}

export default PrivacyPolicy;