import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from '../../assets/images/white-logo.png'

const Navbar = ()=> {
    const user = JSON.parse(localStorage.getItem('loggedInUser'))
    const navigate = useNavigate()
    const [scrolling, setScrolling] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    const mobileMenuRef = useRef()

    const handleLogout = ()=> {
        localStorage.removeItem('loggedInUser');
        navigate('/')
    }
    const handleMobileMenu =()=> {
        setShowMobileMenu(!showMobileMenu)
    }
    const handleClickOutside = (event) => {
        if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
            setShowMobileMenu(false);
        }
      };
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    useEffect(() => {
        if(!user){
            navigate('/')
        }
        const handleScroll = () => {
          if (window.scrollY > 10) {
            setScrolling(true);
          } else {
            setScrolling(false);
          }
        };
        window.addEventListener('scroll', handleScroll);
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      const headerStyle = {
          backgroundColor: showMobileMenu ? '#212529' : 'transparent',
          backgroundColor: scrolling ? '#212529' : 'transparent',
        transition: 'background-color 0.3s ease', 
      };
    return (
        <>
        <div className="navbar-container">
            <header className="fixed top-0 inset-x-0 z-40 bg-gray-900 md:bg-transparent" style={headerStyle}  ref={mobileMenuRef}>
                <div  className="container">
                    <div className="flex items-center justify-between px-6 md:px-20 py-1">
                        <div className="logo w-1/2 md:w-1/4 flex justify-start">
                            <Link to='/'><img src={logo} alt="logoImg" className="w-2/5"/></Link>
                        </div>
                        <div className="w-1/2 block md:hidden">
                            <div className="flex justify-end">
                                <div onClick={handleMobileMenu} className="py-1 px-2 rounded bg-gray-500">
                            {showMobileMenu ? <i className="fa-solid fa-xmark text-xl text-gray-100"></i> :<i className="fa-solid fa-bars text-xl text-gray-100"></i>}
                            </div>
                            </div>
                        </div>
                        <div className="w-3/4 flex justify-end hidden md:block">
                            <div className="main-nav">
                                <ul>
                                    {user ? <>
                                    <li><Link to='/dashboard' className="text-white uppercase hover:text-sky-500">Dashboard</Link></li>
                                    <li><Link to='/inbox' className="text-white uppercase hover:text-sky-500">My task</Link></li>
                                    <li><Link to='/inviteandearn' className="text-white uppercase hover:text-sky-500">Invite & Earn</Link></li>
                                        <li><Link to='/earninghistory' className="text-white uppercase hover:text-sky-500">Earning History</Link></li>
                                    <li><Link to='/profile' className="text-white uppercase hover:text-sky-500">My profile</Link></li>
                                    </> :
                                    <>
                                    {/* <li><Link to='/how-it-works' className="text-white uppercase hover:text-sky-500">How it works</Link></li> */}
                                    <li><Link to='/paymentproof' className="text-white uppercase hover:text-sky-500">Payment proofs</Link></li>
                                    <li><Link to='/contact' className="text-white uppercase hover:text-sky-500">Contact us</Link></li></>}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {showMobileMenu &&  <div className="block md:hidden transition-all">
                            <div className="mobile-nav">
                                <ul className="flex flex-col justify-start gap-4 text-left px-4 py-4">
                                    {user ? <>
                                        <li className="my-0"><Link onClick={()=>setShowMobileMenu(false)} to='/dashboard' className="text-white uppercase hover:text-sky-500">Dashboard</Link></li>
                                        <li><Link onClick={()=>setShowMobileMenu(false)} to='/inbox' className="text-white uppercase hover:text-sky-500">My task</Link></li>
                                        <li><Link onClick={()=>setShowMobileMenu(false)} to='/inviteandearn' className="text-white uppercase hover:text-sky-500">Invite & Earn</Link></li>
                                        <li><Link onClick={()=>setShowMobileMenu(false)} to='/earninghistory' className="text-white uppercase hover:text-sky-500">Earning History</Link></li>
                                        <li><Link onClick={()=>setShowMobileMenu(false)} to='/profile' className="text-white uppercase hover:text-sky-500">My profile</Link></li>
                                        </> :
                                        <>
                                        {/* <li><Link onClick={()=>setShowMobileMenu(false)} to='/how-it-works' className="text-white uppercase hover:text-sky-500">How it works</Link></li> */}
                                        <li><Link onClick={()=>setShowMobileMenu(false)} to='/paymentproof' className="text-white uppercase hover:text-sky-500">Payment proofs</Link></li>
                                        <li><Link onClick={()=>setShowMobileMenu(false)} to='/contact' className="text-white uppercase hover:text-sky-500">Contact us</Link></li></>}
                                </ul>
                            </div>
                        </div>}
                </div>
            </header>
        </div>
        </>
    )
}
export default Navbar;