import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BaseUrl from "../Api/BaseUrl";
import { toast } from "react-toastify";

const Login = ()=> {

    const [formData, setFormData] = useState({})
    const navigate = useNavigate()

    const handleChange = (e)=> {
        setFormData({...formData, [e.target.name]: e.target.value})
    }
    const handleLogin = async(e)=>{
        e.preventDefault()
        const response = await fetch(`${BaseUrl}user/login`, {
            method: 'POST',
            headers: {
                'accept': 'application/json'
            },
            body: new URLSearchParams(formData)
        })
        const resData = await response.json()
        if(resData.responseCode === 200){
            toast.success(resData.responseMessage)
            navigate('/dashboard')
            localStorage.setItem('loggedInUser',JSON.stringify(resData.responsResult) )
        }else{
            toast.error(resData.responseMessage)
        }
    }
    return (
        <>
        <div className="header-subBanner">
            <div className="container px-4 md:px-18 h-full">
                <div className="flex justify-end items-center flex-col md:flex-row h-full">
                   
                </div>
            </div>
        </div>
        <div className="py-10 px-4 md:px-10 container mx-auto">
        <div className="sec-heading pb-4">
            <h2 className="font-bold text-3xl md:text-4xl text-neutral-700 pb-2">Welcome back</h2>
            <small className=" text-base md:text-lg text-neutral-600">Sign in to start making money online</small>
        </div>
        <div className="registration-form py-8 px-0 md:px-4 w-full md:w-1/2 mx-auto bg-white rounded shadow text-left">
            <form onSubmit={handleLogin}>
                <div class="mb-4">
                    <label class="block text-grey-darker text-sm font-bold mb-2" for="email">Email Address</label>
                    <input class="appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="email" type="email" name='email' placeholder="Your email address" onChange={handleChange}/>
                </div>
                <div class="mb-4">
                    <label class="block text-grey-darker text-sm font-bold mb-2" for="password">Password</label>
                    <input class="appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="password" type="password" name='password' placeholder="Your secure password" onChange={handleChange} />
                </div>
                <div className="flex flex-col md:flex-row items-center gap-4">
                    <div className="w-full md:w-1/2">
                        <button type="submit" className="bg-red-600 text-white w-full py-3">Sign in</button>
                    </div>
                    <div className="w-full md:w-1/2">
                        <p>Don't have an account?<Link to='/createaccount'>Register Now</Link></p>
                    </div>
                </div>
            </form>
        </div>
        </div>
        </>
    )
}
export default Login;