import React from "react";
import money from '../../assets/images/online-earning.png'
import { Link } from "react-router-dom";
import UserDetail from "../../components/UserDetail";

const Invite = ()=>{

    const user = JSON.parse(localStorage.getItem('loggedInUser'))
    return (
        <>
        <div className="header-dashboardBanner">
            <div className="container px-4 md:px-18 h-full">
                <div className="flex justify-end items-center flex-col md:flex-row h-full">
                   
                </div>
            </div>
        </div>
        <div className="py-4 px-4 md:px-6 container user-dashboard">
            <p className="text-left pb-2 text-lg text-slate-500">Welcome to dashboard!</p>
            <div className="flex flex-col md:flex-row justify-between gap-4">
                <div className="w-full md:w-1/4">
                    <UserDetail />
                </div>
                <div className="w-full md:w-3/4">
                    <div className="shadow py-4 px-4 pb-4 md:pb-10 rounded-2xl text-center bg-gray-100">
                        <div className="py-4 bg-white inviteEarn">
                        <div className="px-2 pt-4 md:pt-10 w-full text-left">
                                <h3 className="text-2xl text-gray-700 font-bold">Refer and earn Upto 100 INR</h3>
                                <p className="text-gray-700 pt-2">Invite Your Friend to get Extra Cashback Your Friend Complete atleast one First Task You Earn Upto 100 INR from referral Redeem Money in your Account</p>
                                </div>
                                <hr  className="mt-4"/>
                            <div className="w-full py-2 rounded flex flex-col md:flex-row justify-between text-left gap-4">
                                <div className="px-2 pt-10 w-full md:w-3/4">
                                <h3 className="text-2xl text-gray-700 font-bold"> <i className="fa-solid fa-share-from-square"></i> Share with WhatsApp Contacts</h3>
                                <p className="text-gray-700 pt-2">WhatsApp is best option to share your link with your friends and earn money quickly. Just click on the button below to share with your friends and ask them to join taskbazzar.com.</p>
                                <button className="mt-4 py-2 px-4 border bg-green-700 rounded text-sky-200 hover:text-white"><i className="fa-brands fa-whatsapp"></i> Share on whatsapp</button>
                                </div>
                                <img src={money} alt='moneyImg' className="w-3/4 md:w-1/3"/>
                            </div>
                            <hr />
                            {/* <div className="px-2 pt-10 w-full md:w-3/4 text-left">
                                <p className="text-2xl text-gray-700 font-bold"><i className="fa-solid fa-envelope"></i> Share on Email</p>
                                <p className="text-gray-600 pt-2">Login to your Gmail, Yahoo, or other email account, Go to compose and select aLL your friends to send your message with your referral link. You can copy and paste the email given below, which contains your referral link also. You can also promote this message to Facebook, pinterest, twitter or other social platform. The more you will share, The more you will earn.</p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Invite;