import React from "react";

const Counter = ()=> {
    return (
        <>
        <div className="counter-sec">
            <div className="counter px-8">
                <div className="flex flex-col md:flex-row items-center justify-between gap-8 md:gap-1">
                    <div className="w-ful md:w-1/4 text-white">
                        <div className="icon pb-2">
                        <i className="fa-solid fa-users text-5xl md:text-6xl text-white"></i>
                        </div>
                        <strong className="text-3xl md:text-4xl">12310</strong>
                        <br />
                        <span className="text-2xl font-bold">Active Members</span>
                    </div>
                    <div className="w-ful md:w-1/4 text-white">
                        <div className="icon pb-2">
                            <i className="fa-solid fa-calendar-check text-4xl md:text-6xl text-white"></i>
                        </div>
                        <strong className="text-3xl md:text-4xl">3170</strong>
                        <br />
                        <span className="text-2xl font-bold">Task Completed</span>
                    </div>
                    <div className="w-ful md:w-1/4 text-white">
                        <div className="icon pb-2">
                        <i className="fa-solid fa-trophy text-4xl md:text-6xl text-white"></i>
                        </div>
                        <strong className="text-3xl md:text-4xl">1996</strong>
                        <br />
                        <span className="text-2xl font-bold">Rupees Paid Daily</span>
                    </div>
                    <div className="w-ful md:w-1/4 text-white">
                        <div className="icon pb-2">
                        <i className="fa-solid fa-indian-rupee-sign text-4xl md:text-6xl text-white"></i>
                        </div>
                        <strong className="text-3xl md:text-4xl">93367</strong>
                        <br />
                        <span className="text-2xl font-bold">Rupees Paid So Far</span>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Counter;