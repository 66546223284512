import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import icon1 from '../assets/images/flipkart.jpeg'
import icon2 from '../assets/images/hdfc-icon.jpg'
import icon3 from '../assets/images/probo.png'
import icon4 from '../assets/images/Rummy-circle-logo.png'
import icon5 from '../assets/images/Zupee-Logo-1.jpeg'
const Brands = ()=> {
    return (
        <>
        <div className="brand-sec px-4 md:px-16 py-8">
            <h2 className="font-bold text-2xl md:text-3xl text-gray-800 uppercase">Brands We Served</h2>
            <div className="brand-slider pt-8">
                 <Swiper navigation={true} 
                modules={[Autoplay ,Navigation]} loop={true}
                slidesPerView={1}
                spaceBetween={50}
                autoplay={{
                    delay: 10000,
                    disableOnInteraction: false
                }} 
                breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 50,
                    },
                  }}
                className="mySwiper">
            <SwiperSlide><img src={icon1} alt="slide-img"/></SwiperSlide>
            <SwiperSlide><img src={icon2} alt="slide-img"/></SwiperSlide>
            <SwiperSlide><img src={icon3} alt="slide-img"/></SwiperSlide>
            <SwiperSlide><img src={icon4} alt="slide-img"/></SwiperSlide>
            <SwiperSlide><img src={icon5} alt="slide-img"/></SwiperSlide>
        </Swiper>
            </div>
        </div>
        </>
    )
}
export default Brands;