import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import BaseUrl from '../Api/BaseUrl';

export const fetchUserList = createAsyncThunk(
    'admin/list',
    async ({adminId, currentPage}) => {
        const response = await fetch(`${BaseUrl}admin/list?adminId=${adminId}&page=${currentPage}`);
        const responseData = await response.json();
        console.log('userList',responseData)
        return responseData
      }
)
export const fetchTotalUser = createAsyncThunk(
  'admin/totalUser',
  async () => {
      const response = await fetch(`${BaseUrl}admin/totalUser`);
      const responseData = await response.json();
      return responseData
    }
)
export const fetchUserDetail = createAsyncThunk(
    'admin/view',
    async ({adminId, userId}) => {
        const response = await fetch(`${BaseUrl}admin/view?adminId=${adminId}&userId=${userId}`);
        const responseData = await response.json();
        return responseData
      }
)
export const userStatusBlock = createAsyncThunk(
  'admin/block',
  async ({adminId, userId}) => {
      const response = await fetch(`${BaseUrl}admin/block?adminId=${adminId}&_id=${userId}`,{
        method: 'PUT',
        headers: {
            'accept': 'application/json'
        },
      });
      const responseData = await response.json();
      return responseData
    }
)
export const userStatusActive = createAsyncThunk(
  'admin/AprovedUser',
  async ({adminId, userId}) => {
      const response = await fetch(`${BaseUrl}admin/AprovedUser?adminId=${adminId}&_id=${userId}`,{
        method: 'PUT',
        headers: {
            'accept': 'application/json'
        },
      });
      const responseData = await response.json();
      return responseData
    }
)


export const UserSlice = createSlice({
    name: 'user',
    initialState: {
        list: [],
        userWalletList: [],
        total: null,
        details: null,
        loading : false,
        error : null
      },

      reducers: {
    
      },

      extraReducers: (builder) => {
        // fetch builder
       builder.addCase(fetchUserList.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(fetchUserList.fulfilled, (state, action) => {
         state.list =  action.payload.responseResult;
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(fetchUserList.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
        // totaluser builder
        builder.addCase(fetchTotalUser.pending, (state, action) => {
          state.loading =  true;
        });
        builder.addCase(fetchTotalUser.fulfilled, (state, action) => {
          state.total =  action.payload.responseResult;
          state.loading =  false;
          state.error =  false;
        });
        builder.addCase(fetchTotalUser.rejected, (state, action) => {
          state.error =  true;
          state.loading =  false;
        });
       builder.addCase(fetchUserDetail.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(fetchUserDetail.fulfilled, (state, action) => {
         state.details =  action.payload.responseResult;
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(fetchUserDetail.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
       builder.addCase(userStatusBlock.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(userStatusBlock.fulfilled, (state, action) => {
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(userStatusBlock.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
       builder.addCase(userStatusActive.pending, (state, action) => {
        state.loading =  true;
      });
      builder.addCase(userStatusActive.fulfilled, (state, action) => {
        state.loading =  false;
        state.error =  false;
      });
      builder.addCase(userStatusActive.rejected, (state, action) => {
        state.error =  true;
        state.loading =  false;
      });
    }
})

export default UserSlice.reducer