import React from "react";
import UserDetail from "../../components/UserDetail";
import PostbackUrl from "../../components/PostbackUrl";
import { useNavigate } from "react-router-dom";

const Profile = ()=> {
    const user = JSON.parse(localStorage.getItem('loggedInUser'))
    const navigate = useNavigate()

    const handleLogout = ()=> {
        localStorage.removeItem('loggedInUser')
        navigate('/')
    }

    return (
        <>
        <div className="header-dashboardBanner">
            <div className="container px-4 md:px-18 h-full">
                <div className="flex justify-end items-center flex-col md:flex-row h-full">
                   
                </div>
            </div>
        </div>
         <div className="py-4 px-4 md:px-6 container user-dashboard">
            <p className="text-left pb-2 text-lg text-slate-500">Welcome to dashboard!</p>
            <div className="flex flex-col md:flex-row justify-between gap-4">
                <div className="w-full md:w-1/4">
                    <UserDetail />
                </div>
                <div className="w-full md:w-3/4">
                    <div className="shadow py-4 px-2 md:px-2 pb-4 md:pb-10 rounded-2xl text-center bg-gray-100">
                        <div className="py-4 bg-white px-2 md:px-4">
                            <div className="flex flex-col md:flex-row justify-between items-center mb-2 text-left">
                                <h4 className="font-bold text-xl md:text-2xl text-grey-800 mb-0">User Details</h4>
                                <div className="flex justify-end gap-4">
                                    <button className="py-2 px-2 bg-sky-600 text-sky-100 rounded hover:text-white">Edit profile</button>
                                    <button className="py-2 px-2 bg-gray-500 text-sky-100 rounded hover:text-white" onClick={handleLogout}><i className="fa-solid fa-arrow-right-from-bracket"></i> Logout</button>
                                </div>
                            </div>
                            <hr />
                            <div className="flex gap-8 pt-4">
                                <p className="font-bold w-1/4 text-left">customer Id: </p>
                                <span>{user._id}</span>
                            </div>
                            <div className="flex gap-8 pt-4">
                                <p className="font-bold w-1/4 text-left">Name: </p>
                                <span>{user.name}</span>
                            </div>
                            <div className="flex gap-8 pt-4">
                                <p className="font-bold w-1/4 text-left">Email: </p>
                                <span>{user.email}</span>
                            </div>
                            <div className="flex gap-8 pt-4">
                                <p className="font-bold w-1/4 text-left">Phone: </p>
                                <span>{user.mobileNumber}</span>
                            </div>
                            <div className="flex gap-8 pt-4">
                                <p className="font-bold w-1/4 text-left">Address: </p>
                                <span>{user.address}</span>
                            </div>
                            <div className="py-2 px-0 md:px-2">
                            {/* <PostbackUrl /> */}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}


export default Profile;