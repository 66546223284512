import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../../components/global/Admin/Navbar";
import Sidebar from "../../../../components/global/Admin/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { addOffer } from "../../../../Redux/OfferSlice";
import { fetchAdvertiserList } from "../../../../Redux/AdvertiserSlice";

const AddOffer = ()=> {
    const [formData, setFormData] = useState({})
    const [imageFile, setImageFile] = useState(null);
    const advertiser = useSelector((state)=> state.advertiser.list)
    const admin = JSON.parse(localStorage.getItem('taskbuzzarAdmin'))
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        console.log('file', file)
            setImageFile(file);
      };

    const handleInput = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value, adminId: admin._id})
    }
    const handleLogin = async (e)=> {
        e.preventDefault();
        console.log('imageFile', imageFile)
        const form = new FormData();
        form.append('adminId', admin._id);
        form.append('OfferIcon', imageFile);
        form.append('advertiserId', formData?.advertiserId);
        form.append('OfferName', formData?.OfferName);
        form.append('buttonName', formData?.buttonName);
        form.append('category', formData?.category);
        form.append('offerModel', formData?.offerModel);
        form.append('revenue', formData?.revenue);
        form.append('payout', formData?.payout);
        form.append('OfferDetails', formData?.OfferDetails);
        form.append('trackingUrl', formData?.trackingUrl);
        const advertiserRes = await dispatch(addOffer(form));
        const res = advertiserRes.payload 
        if(res.responseCode === 200){
            toast.success(res.responseMessage)
            navigate('../offer');
        }else{
            toast.error(res.responseMessage)
        }
        console.log('advertiserRes', res)
    }
    useEffect(()=>{
        dispatch(fetchAdvertiserList(admin._id))
    },[])
    return (
        <>
        <div className="container flex flex-col h-screen">
            <AdminNavbar />
            <div className="main flex justify-between grow">
                <div className="w-1/5">
                    <Sidebar />
                </div>
                <div className="w-4/5 py-4 px-4 text-left">
                    <div className="rounded bg-gray-100 px-4 py-4">
                        <div className="w-4/5 bg-white py-4 px-4 rounded text-left">
                        <h2 className="font-bold text-xl text-gray-700 pb-4">Create offer</h2>
                            <form>
                                <div className="flex justify-between items-center gap-4">
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="OfferIcon">Offer Icon</label>
                                        <input type="file" 
                                            id="OfferIcon" 
                                            name="OfferIcon" 
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            required  
                                            className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div>
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="advertiserId">Advertiser</label>
                                        <select className="py-2 px-2 border border-grey-300 rounded"
                                            id="advertiserId" 
                                            name="advertiserId" 
                                            value={formData?.advertiserId} 
                                            onChange={(e)=>handleInput(e)} > 
                                            <option value='' hidden>Select advertiser</option>
                                            {advertiser.map((item)=> <option key={item._id} value={item._id}>(ID:{item._id}) {item.fullName}</option>)}
                                        </select>
                                    </div> 
                                </div>
                                <div className="flex justify-between items-center gap-4">
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="OfferName">Offer Name</label>
                                        <input type="text" id="OfferName" name="OfferName" value={formData?.OfferName} onChange={(e)=>handleInput(e)}  className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div>
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="buttonName">Button Name</label>
                                        <input type="text" id="buttonName" name="buttonName" value={formData?.buttonName} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div> 
                                </div>
                                <div className="flex justify-between items-center gap-4">
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="category">Category</label>
                                        <input type="text" id="category" placeholder="" name="category" value={formData?.category} onChange={(e)=>handleInput(e)}  className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div>
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="offerModel">Offer Model</label>
                                        <input type="text" id="offerModel" name="offerModel" value={formData?.offerModel} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div> 
                                </div>
                                <div className="flex justify-between items-center gap-4">
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="revenue">Revenue</label>
                                        <input type="text" id="revenue" name="revenue" value={formData?.revenue} onChange={(e)=>handleInput(e)}  className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div>
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="payout">Payout</label>
                                        <input type="text" id="payout" name="payout" value={formData?.payout} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div> 
                                </div>
                                <div className="flex justify-between items-center">
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="OfferDetails">Offer Details</label>
                                        <input type="text" id="OfferDetails" name="OfferDetails" value={formData?.OfferDetails} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div> 
                                </div>
                                <div className="flex justify-between items-center">
                                    <div className="flex flex-col pb-4 w-full">
                                        <label htmlFor="trackingUrl">TrackingUrl</label>
                                        <textarea id="trackingUrl" name="trackingUrl" value={formData?.trackingUrl} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded"/>
                                    </div> 
                                </div>
                            <button type="submit" onClick={handleLogin} className="py-2 px-4 bg-sky-700 rounded text-sky-100 hover:text-white">Submit</button>
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
        </div>
        </>
    )
}

export default AddOffer;