import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../../components/global/Admin/Sidebar";
import AdminNavbar from "../../../components/global/Admin/Navbar";
import { Link, useLocation } from "react-router-dom";
import BaseUrl from "../../../Api/BaseUrl";
import UserTable from "../../../components/UserTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserList } from "../../../Redux/UserSlice";
import TablePagination from "../../../components/Pagination";

const Users = ()=> {
    const admin = JSON.parse(localStorage.getItem('taskbuzzarAdmin'))
    const [totalPage, setTotalPage] = useState()
    const {search} = useLocation()
    const params = new URLSearchParams(search);
    const getCurrentPage = params.get('page')
    const userList = useSelector((state)=> state.user.list)
    const dispatch = useDispatch()

    const fetchUserListApi = async ()=>{ 
        const userListRes = await dispatch(fetchUserList({adminId: admin._id, currentPage:getCurrentPage}))
        const res = userListRes.payload;
        if(res.responseCode === 200){
            setTotalPage(res.totalPages)
        }
    }
    useEffect(()=> {
        fetchUserListApi()
    },[])
    useEffect(()=> { 
        fetchUserListApi()
        console.log('getCurrentPage', getCurrentPage)
    },[getCurrentPage])
    return (
        <>
        <div className="container flex flex-col h-screen">
            <AdminNavbar />
            <div className="main flex justify-between grow">
                <div className="w-1/5">
                    <Sidebar />
                </div>
                <div className="w-4/5 py-4 px-4 text-left">
                    {/* <h2 className="font-bold text-2xl text-gray-800 pb-3">Offer</h2> */}
                    <div className="rounded bg-gray-100 px-4 py-4">
                        <div className="flex justify-between items-center">
                            <input type="text" placeholder="search" className="py-2 px-2 rounded" />
                            {/* <button className="py-2 px-4 bg-sky-700 text-sky-100 hover:text-white rounded"> <i className="fa-solid fa-plus"></i> Create offer</button> */}
                        </div>
                        <div className="w-full bg-white py-2 px-2 rounded flex justify-between text-left gap-4 mt-4 overflow-x-scroll">
                        <div className="table w-full pt-4">
                                    <table class='shadow rounded w-full'>
                                    <thead class='sticky top-0'>
                                        <tr class='text-left justify-between'>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Id</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Name</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Email</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Phone</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Status</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Wallet</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Payout</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {userList.map((item)=> 
                                    <UserTable item={item}/>
                                    )}
                                    </tbody>
                                    </table>
                        </div>
                        </div>
                        <TablePagination totalPage={totalPage} path='/v2/user' />
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Users;