import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/global/Admin/Sidebar";
import AdminNavbar from "../../../components/global/Admin/Navbar";
import { Link } from "react-router-dom";
import BaseUrl from "../../../Api/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdvertiserList } from "../../../Redux/AdvertiserSlice";

const Advertiser = ()=> {
    const admin = JSON.parse(localStorage.getItem('taskbuzzarAdmin'))
    const advertiser = useSelector((state)=> state.advertiser.list);
    const dispatch = useDispatch()

    useEffect(()=> {
        dispatch(fetchAdvertiserList(admin._id))
    },[])
    return (
        <>
        <div className="container flex flex-col h-screen">
            <AdminNavbar />
            <div className="main flex justify-between grow">
                <div className="w-1/5">
                    <Sidebar />
                </div>
                <div className="w-4/5 py-4 px-4 text-left">
                    {/* <h2 className="font-bold text-2xl text-gray-800 pb-3">Offer</h2> */}
                    <div className="rounded bg-gray-100 px-4 py-4">
                        <div className="flex justify-between items-center">
                            <input type="text" placeholder="search" className="py-2 px-2 rounded" />
                            <button className="py-2 px-4 bg-sky-700 text-sky-100 hover:text-white rounded"> <Link to='../addadvertiser'><i className="fa-solid fa-plus"></i> Create advertiser</Link></button>
                        </div>
                        <div className="w-full bg-white py-2 px-2 rounded flex justify-between text-left gap-4 mt-4">
                        <div className="table pt-4 w-full">
                                    <table class='shadow rounded w-full'>
                                    <thead class='sticky top-0'>
                                        <tr class='text-left justify-between'>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Id</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Name</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Address</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Email</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Phone</th>
                                            <th className="border bg-gray-100 border rounded-tl border-gray-300 font-bold py-2 px-2">Company name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {advertiser.map((item)=> <tr className='text-left justify-between' key={item._id}>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item._id}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.fullName}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.address}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.email}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.mobileNumber}</td>
                                            <td className="border border rounded-tl border-gray-300 py-2 px-2">{item.companyName}</td>
                                        </tr>)}
                                    </tbody>
                                    </table>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
        </div>
        </>
    )
}
export default Advertiser;