import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import BaseUrl from '../Api/BaseUrl';

export const fetchUserWallet = createAsyncThunk(
    'user/viewWallet',
    async (userId) => {
        const response = await fetch(`${BaseUrl}user/viewWallet?userId=${userId}`);
        const responseData = await response.json();
        return responseData
      }
)
export const fetchWithdrawRequest = createAsyncThunk(
    'user/withdrawRequestList',
    async ({adminId, currentPage}) => {
        const response = await fetch(`${BaseUrl}user/withdrawRequestList?adminId=${adminId}`);
        const responseData = await response.json()
        return responseData
      }
)
export const fetchUserWithdrawRequest = createAsyncThunk(
    'user/UserWithdrawRequestList',
    async (userId) => {
        const response = await fetch(`${BaseUrl}user/UserWithdrawRequestList?userId=${userId}`);
        const responseData = await response.json()
        return responseData
      }
)
export const fetchEarningHistory = createAsyncThunk(
    'user/earningHistory',
    async (userId) => {
        const response = await fetch(`${BaseUrl}user/earningHistory?userId=${userId}`);
        const responseData = await response.json();
        return responseData
      }
)
export const addPayoutToAccount = createAsyncThunk(
    'user/adminIncreasePayout',
    async ({adminId, formData}) => {
        const response = await fetch(`${BaseUrl}user/adminIncreasePayout?adminId=${adminId}`, {
          method: 'PUT',
          headers: {
              'accept': 'application/json'
          },
          body: new URLSearchParams(formData)
      });
        const responseData = await response.json();
        return responseData
      }
)
export const decreasePayoutFromAccount = createAsyncThunk(
    'user/adminDecreasePayout',
    async ({adminId, formData}) => {
        const response = await fetch(`${BaseUrl}user/adminDecreasePayout?adminId=${adminId}`, {
          method: 'PUT',
          headers: {
              'accept': 'application/json'
          },
          body: new URLSearchParams(formData)
      });
        const responseData = await response.json();
        return responseData
      }
)
// export const approveAmountToUser = createAsyncThunk(
//     'user/adminAproveAmount',
//     async ({adminId, formData}) => {
//         const response = await fetch(`${BaseUrl}user/adminAproveAmount?adminId=${adminId}`, {
//           method: 'PUT',
//           headers: {
//               'accept': 'application/json'
//           },
//           body: new URLSearchParams(formData)
//       });
//         const responseData = await response.json();
//         return responseData
//       }
// )
export const withdrawAmountReq = createAsyncThunk(
    'user/withdrawAmount',
    async (formdata) => {
        const response = await fetch(`${BaseUrl}user/withdrawAmount`, {
          method: 'POST',
          headers: {
              'accept': 'application/json'
          },
          body: new URLSearchParams(formdata)
      });
        const responseData = await response.json();
        return responseData
      }
)
export const addUserWallet = createAsyncThunk(
    'user/userWallet',
    async ({userId}) => {
        const response = await fetch(`${BaseUrl}user/userWallet`, {
          method: 'POST',
          headers: {
              'accept': 'application/json'
          },
          body: new URLSearchParams({userId})
      });
        const responseData = await response.json();
        return responseData
      }
)
export const approveAmountByAdmin = createAsyncThunk(
    'user/adminAproveAmount',
    async ({adminId, transactionId}) => {
        const response = await fetch(`${BaseUrl}user/adminAproveAmount?adminId=${adminId}`, {
          method: 'PUT',
          headers: {
              'accept': 'application/json'
          },
          body: new URLSearchParams({transactionId})
      });
        const responseData = await response.json();
        return responseData
      }
)

export const UserWalletSlice = createSlice({
    name: 'userWallet',
    initialState: {
        wallet: null,
        withdrawRequest: [],
        userWithdrawRequest: [],
        earningHistory: [],
        details: [],
        loading : false,
        error : null
      },

      reducers: {
    
      },

      extraReducers: (builder) => {
        // fetch builder
       builder.addCase(fetchUserWallet.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(fetchUserWallet.fulfilled, (state, action) => {
         state.wallet =  action.payload.responseResult;
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(fetchUserWallet.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
        // Add user wallet builder
       builder.addCase(addUserWallet.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(addUserWallet.fulfilled, (state, action) => {
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(addUserWallet.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
        // fetch builder
       builder.addCase(fetchWithdrawRequest.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(fetchWithdrawRequest.fulfilled, (state, action) => {
         state.withdrawRequest =  action.payload.responseResult;
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(fetchWithdrawRequest.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
        // fetch builder
       builder.addCase(fetchUserWithdrawRequest.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(fetchUserWithdrawRequest.fulfilled, (state, action) => {
         state.userWithdrawRequest =  action.payload.responseResult;
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(fetchUserWithdrawRequest.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
        // fetch builder
       builder.addCase(fetchEarningHistory.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(fetchEarningHistory.fulfilled, (state, action) => {
         state.earningHistory =  action.payload.responseResult;
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(fetchEarningHistory.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
        // AddPayout builder
       builder.addCase(addPayoutToAccount.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(addPayoutToAccount.fulfilled, (state, action) => {
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(addPayoutToAccount.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
        // DecreasePayout builder
       builder.addCase(decreasePayoutFromAccount.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(decreasePayoutFromAccount.fulfilled, (state, action) => {
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(decreasePayoutFromAccount.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
        // withdraw builder
       builder.addCase(withdrawAmountReq.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(withdrawAmountReq.fulfilled, (state, action) => {
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(withdrawAmountReq.rejected, (state, action) => { 
         state.error =  true;
         state.loading =  false;
       });
        // approveAmount builder
       builder.addCase(approveAmountByAdmin.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(approveAmountByAdmin.fulfilled, (state, action) => {
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(approveAmountByAdmin.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });

    }
})

export default UserWalletSlice.reducer