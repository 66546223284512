import React, { useEffect, useState } from "react";
import BaseUrl from "../../Api/BaseUrl";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const AdminLogin = ()=> {
    const [formData, setFormData] = useState({})
    const admin = JSON.parse(localStorage.getItem('taskbuzzarAdmin'))
    const navigate = useNavigate()

    const handleInput = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const handleLogin = async (e)=> {
        e.preventDefault();
        const response = await fetch(`${BaseUrl}admin/login`, {
            method: 'POST',
            headers: {
                'accept': 'application/json'
            },
            body: new URLSearchParams(formData)
        }) 
        const resData = await response.json()
        if(resData.responseCode === 200){
            localStorage.setItem('taskbuzzarAdmin', JSON.stringify(resData.responsResult))
            toast.success(resData.responseMessage)
            navigate('../dashboard')
        }else {
            toast.error(resData.responseMessage)
        }
    }

    useEffect(()=>{
        if(admin){
            navigate('../dashboard')
        }
    },[])
    return (
        <>
        <div className="main-form container h-full">
            <div className="login-wrapper text-left flex justify-center items-center h-screen">
        <div className="card w-2/5 mx-auto shadow py-4 px-4 ">
            <h2 className="page-title font-bold text-2xl text-sky-700 pb-4 text-center">Admin Panel</h2>
            <form className="pt-4">
                <div className="flex flex-col pb-4">
                <label htmlFor="username">Email</label>
                <input type="email" id="username" placeholder="Enter your username" name="email" value={formData?.email} onChange={(e)=>handleInput(e)}  className="py-2 px-2 border border-grey-300 rounded"/>
                </div>
                <div>
                <div className="flex flex-col pb-4">
                <label htmlFor="password">Password</label>
                <input type="password" id="password" placeholder="Enter your password" name="password" value={formData?.password} onChange={(e)=>handleInput(e)} className="py-2 px-2 border border-grey-300 rounded" />
                </div>
                </div>
                <div className="flex justify-between items-center">
                    <button type="submit" onClick={handleLogin} className="py-2 px-6 bg-sky-700 rounded text-sky-100 hover:text-white">Login</button>
                    <button className="py-2 px-2 hover:underline text-sky-500 hover:text-sky-700"><Link to='../forgotpassword'><i className="fa-solid fa-lock text-gray-600"></i> Forgot Password</Link></button>
                </div>
            </form>
           </div>
           </div>
           </div>
        </>
    )
}
export default AdminLogin;